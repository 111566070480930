export const formatearFechaPedido = (fechaPedidoString) => {
    try {
      const fechaPedido = new Date(fechaPedidoString)

      if (isNaN(fechaPedido)) {
        return "Fecha inválida"
      }

      const dia = fechaPedido.getDate()
      const mesIndex = fechaPedido.getMonth()
      const anio = fechaPedido.getFullYear()

      const meses = [
        "enero",
        "febrero",
        "marzo",
        "abril",
        "mayo",
        "junio",
        "julio",
        "agosto",
        "septiembre",
        "octubre",
        "noviembre",
        "diciembre",
      ]

      const mes = meses[mesIndex]

      return `${dia} de ${mes} de ${anio}`
    } catch (error) {
      console.error(`Error al formatear la fecha: ${fechaPedidoString}`, error)
      return "Fecha inválida"
    }
  }