
import React, { createContext, useContext, useState } from "react";

const RegisterVendContext = createContext();

export const useRegisterVendContext = () => {
  return useContext(RegisterVendContext);
};

export const RegisterVendProvider = ({ children }) => {
  const [registerData, setRegisterData] = useState({
    emaVendedor: "",
    celVendedor: null,
    codVendedor: "",
    celVendedorDespuesSms:null
  });

  const updateRegisterData = (dataToUpdate) => {
    setRegisterData((prevData) => ({ ...prevData, ...dataToUpdate }));
  };

  return (
    <RegisterVendContext.Provider
      value={{
        registerData,
        updateRegisterData,
      }}
    >
      {children}
    </RegisterVendContext.Provider>
  );
};
