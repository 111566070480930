// ConfirmacionCompra.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ResponseCompra = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ref_payco = searchParams.get('ref_payco');
  const [transactionData, setTransactionData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTransactionData = async () => {
      if (!ref_payco) {
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`https://secure.epayco.co/validation/v1/reference/${ref_payco}`);
        const result = await response.json();
        if (result.success) {
          setTransactionData(result.data);
        } else {
          console.error('Error fetching transaction data:', result.message);
        }
      } catch (error) {
        console.error('Error fetching transaction data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactionData();
  }, [ref_payco]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!transactionData) {
    return <div>No transaction data available</div>;
  }

  return (
  <div>
      <h1>Transaction Response</h1>
      <p><strong>Reference:</strong> {transactionData.x_ref_payco}</p>
      <p><strong>Invoice ID:</strong> {transactionData.x_id_factura}</p>
      <p><strong>Description:</strong> {transactionData.x_description}</p>
      <p><strong>Amount:</strong> {transactionData.x_amount} {transactionData.x_currency_code}</p>
      <p><strong>Bank Name:</strong> {transactionData.x_bank_name}</p>
      <p><strong>Card Number:</strong> {transactionData.x_cardnumber}</p>
      <p><strong>Response:</strong> {transactionData.x_response}</p>
      <p><strong>Approval Code:</strong> {transactionData.x_approval_code}</p>
      <p><strong>Transaction ID:</strong> {transactionData.x_transaction_id}</p>
      <p><strong>Transaction Date:</strong> {transactionData.x_transaction_date}</p>
      <p><strong>Response Code:</strong> {transactionData.x_cod_response}</p>
      <p><strong>Response Reason:</strong> {transactionData.x_response_reason_text}</p>
      <p><strong>Transaction State:</strong> {transactionData.x_transaction_state}</p>
      <p><strong>Franchise:</strong> {transactionData.x_franchise}</p>
      <p><strong>Business:</strong> {transactionData.x_business}</p>
      <p><strong>Customer Email:</strong> {transactionData.x_customer_email}</p>
      <p><strong>IP Address:</strong> {transactionData.x_customer_ip}</p>
      <p><strong>Test Request:</strong> {transactionData.x_test_request}</p>
    </div>
  );
};

export default ResponseCompra;

