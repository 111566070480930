import React from 'react';

const FiltroGenerico = ({ listaItems, handleItemChange, items, titulo, idProp, nombreProp }) => {
  const renderItem = (item) => (
    <div key={item[idProp]} className="flex items-center mb-2">
      <input
        type="checkbox"
        id={`item-${item[idProp]}`}
        name={`item-${item[idProp]}`}
        value={item[idProp]}
        onChange={() => handleItemChange(item[idProp])} // Cambiamos cómo se llama a handleItemChange
        checked={items.includes(item[idProp])}
        className="mr-2"
      />
      <label htmlFor={`item-${item[idProp]}`} className="text-sm">
        {item[nombreProp]}
      </label>
    </div>
  );

  return (
    <div className="bg-white border rounded-lg shadow mb-5 w-full">
      <h1 className="border border-b-2 p-3 font-bold">{titulo}</h1>
      <div className="p-4">
        {listaItems.map((item) => renderItem(item))}
      </div>
    </div>
  );
};

export default FiltroGenerico;
