import React, { useEffect, useState, useRef, useContext } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEnvelope,
  faFileLines,
  faCircleCheck,
  faEye,
  faEyeSlash
} from "@fortawesome/free-regular-svg-icons"
import { faCircleInfo, faPhone } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import { useRegisterContext } from "../../contexts/RegisterContext"
import {
  getCitys,
  getDptos,
} from "../../utils/apiCiudades"
import {
  registroEmail,
  registroTelefono,
  registroVendedor,
  validarSms,
} from "../../utils/apiRegistro"
import Tooltip from "../../components/Tooltip"



const RegistroVendedor = () => {
  const { registerData} = useRegisterContext()
  const navigate = useNavigate();

  const handleClickAgregarEmail = () => {
    navigate("/registro-vendedor/email");
  };

  const handleClickAgregarTelefono = () => {
    navigate("/registro-vendedor/telefono");
  };

  const handleClickFormulario = () => {
    navigate("/registro-vendedor/formulario");
  };


  return (
    <div className="flex flex-col pt-20 items-center bg-zinc-100">
      <div className="bg-white rounded-lg shadow-lg p-14 max-w-lg">
        <div className="mb-4">
          <h1 className="text-2xl font-medium text-primario">
            Completa los datos para crear tu cuenta
          </h1>
        </div>
        <div className="flex flex-col items-start mb-4">
          <div
            className={`flex items-center rounded-md py-5 px-4 justify-start w-full ${
              registerData.emaVendedor ? "" : "shadow-md"
            }`}
          >
            <FontAwesomeIcon
              icon={faEnvelope}
              className="h-5 text-secundario border rounded-full p-2 mr-4"
            />
            <h1 className="mr-auto"> Agrega tu email</h1>
            {!registerData.emaVendedor ? (
              <button
                onClick={handleClickAgregarEmail}
                className="bg-secundario rounded py-2 px-4 text-sm text-white font-medium"
              >
                Agregar
              </button>
            ) : (
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="h-5 text-secundario pr-9"
              />
            )}
          </div>
          <div
            className={`flex items-center rounded-md py-5 px-4 justify-start  w-full ${
              registerData.emaVendedor && !registerData.celVendedor ? "shadow" : ""
            }`}
          >
            <FontAwesomeIcon
              icon={faPhone}
              className="h-5 text-secundario border rounded-full p-2 mr-4"
            />
            <h1 className="mr-auto"> Valida tu télefono</h1>
            {registerData.emaVendedor && !registerData.celVendedorDespuesSms && (
              <button
                onClick={handleClickAgregarTelefono}
                className="bg-secundario rounded py-2 px-4 text-sm text-white font-medium"
              >
                Agregar
              </button>
            )}
            {registerData.emaVendedor && registerData.celVendedorDespuesSms && (
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="h-5 text-secundario pr-9"
              />
            )}
          </div>
          <div
            className={`flex items-center rounded-md py-5 px-4 justify-start  w-full ${
              registerData.emaVendedor && registerData.celVendedor && !registerData.indEstadoVend
                ? "shadow"
                : ""
            }`}
          >
            <FontAwesomeIcon
              icon={faFileLines}
              className="h-5 text-secundario border rounded-full p-2 mr-4"
            />
            <h1 className="mr-auto"> Completa el Formulario</h1>
            {registerData.emaVendedor && registerData.celVendedor && registerData.indEstadoVend === 0 && (
              <button
                onClick={handleClickFormulario}
                className="bg-secundario rounded py-2 px-4 text-sm text-white font-medium"
              >
                Agregar
              </button>
            )}
            {registerData.emaVendedor && registerData.celVendedor && registerData.indEstadoVend !== 0 && (
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="h-5 text-secundario pr-9"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};



export const Email = () => {
  const [email, setEmail] = useState("")
  const [isValidEmail, setIsValidEmail] = useState(true)
  const navigate = useNavigate()
  const { registerData, updateRegisterData } = useRegisterContext()

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const emailIsValid = validateEmail();
    setIsValidEmail(emailIsValid);
  
    if (emailIsValid) {
      try {
        const response = await registroEmail({ email });
  
        console.log("response", response);
  
        if (response && response.status && response.datos) {
          const { codUsuario, indEstado, celUsuario } = response.datos;
  
          updateRegisterData({
            emaVendedor: email,
            codVendedor: codUsuario,
            celVendedor: celUsuario,
            indEstadoVend: indEstado,
          });
  
          // Escenarios según la respuesta del servidor
          if (indEstado=== 2) {
            toast.success(`Ya existe una cuenta con este correo...`);
            setTimeout(() => {
              navigate("/login");
            }, 1500);
          } else if (indEstado === 1) {
            toast.success("¡Su cuenta está en proceso de validación!");
            setTimeout(() => {
              navigate("/registro-vendedor/validacion-cuenta");
            }, 1500);
          } else if (indEstado === 3) {
            toast.error(
              "La cuenta está bloqueada. Por favor, contacta con soporte."
            );
          } else {
            toast.success(
              "Email creado con éxito. Por favor, continúa con el registro de teléfono."
            );
            setTimeout(() => {
              navigate("/registro-vendedor/telefono");
            }, 1500);
          }
        } else if (response && !response.status && response.datos) {
          const { codUsuario, emaUsuario, celUsuario, indEstado, tipUsuario } = response.datos;
  
          if (tipUsuario === 2 ) {
            updateRegisterData({
              emaUsuario,
              codUsuario,
              celUsuario,
              indEstado
              
            });
            // Nueva condición: Si tipoUsuario es 2, navega a "/registro-usuario/formulario"
            toast.success(`Cuenta de usuario. Redirigiendo al formulario de usuario...`);
            setTimeout(() => {
              navigate("/registro-usuario/telefono");
            }, 1500);
          } else if (celUsuario === null) {
            // Escenario 3: El email está registrado, pero no se ha completado el teléfono.
            // Actualiza los valores en el estado
            updateRegisterData({
              emaVendedor: emaUsuario,
              codVendedor: codUsuario,
              celVendedor: celUsuario,
              indEstadoVend: indEstado,
            });
            toast.info(
              "El correo electrónico ya está registrado. Por favor, continúa con el registro de teléfono."
            );
            setTimeout(() => {
              navigate("/registro-vendedor/telefono");
            }, 3000);
          } else {
            // Escenario 2: El email y el teléfono ya están registrados en el servidor.
            // Actualiza los valores en el estado
            updateRegisterData({
              emaVendedor: emaUsuario,
              codVendedor: codUsuario,
              celVendedor: celUsuario,
              indEstadoVend: indEstado,
            });
            console.log("Valores actualizados en el estado:");
            console.log("Email vende:", emaUsuario);
            console.log("Código de Vendedor:", codUsuario);
            console.log("Celular de Vendedor:", celUsuario);
            console.log("Indicador de Estado:", indEstado);
            toast.info(
              "El correo electrónico y el teléfono ya están registrados. Por favor, completa el formulario de registro."
            );
            setTimeout(() => {
              navigate("/registro-vendedor/formulario");
            }, 3000);
          }
        } else {
          // Escenario 1: El email no es válido.
          console.log("Correo electrónico no válido");
        }
      } catch (error) {
        console.error("Error en la solicitud", error);
      }
    }
  };
  

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
    setIsValidEmail(true)
  }

  return (
    <div className="flex flex-col pt-20 items-center bg-zinc-100">
      <div className="bg-white rounded-lg shadow-lg p-14 max-w-lg overflow-hidden">
        <div className="mb-4">
          <h1 className="text-2xl font-medium text-primario">
            Ingresa tu email
          </h1>
          <p className="text-sm pt-4">
            Asegúrate de tener acceso al correo que registres 
          </p>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col">
              <label
                htmlFor="email"
                className="text-sm text-gray-500 mb-1 pl-2 mt-6"
              >
                Digite su email
              </label>
              <input
                type="email"
                className={`border focus:outline-secundario p-3 rounded-lg ${
                  !isValidEmail && "border-red-500"
                }`}
                id="email"
                value={email}
                onChange={handleEmailChange}
                required
              />
              {!isValidEmail && (
                <p className="text-red-500 text-sm mt-1">
                  Por favor, ingresa un correo electrónico válido.
                </p>
              )}
            </div>

            <button
              type="submit"
              className={`${
                !isValidEmail ? "bg-gray-300" : "bg-secundario"
              } p-3 w-full text-white mt-4 rounded-lg text-base font-medium`}
              disabled={!isValidEmail}
            >
              Continuar
            </button>
          </form>
        </div>
      </div>
      <ToastContainer position="top-center" style={{ width: "500px" }} />
    </div>
  )
}



export const Telefono = () => {
  const navigate = useNavigate();
  const { registerData, updateRegisterData } = useRegisterContext();
  const [telefono, setTelefono] = useState("");
  const [isValidTelefono, setIsValidTelefono] = useState(true);

  const handleTelefonoChange = (e) => {
    setTelefono(e.target.value);
    setIsValidTelefono(true);
  };

  const validateTelefono = () => {
    return telefono.length === 10;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsValidTelefono(validateTelefono());

    if (isValidTelefono) {
      try {
        await registroTelefono(
          { codUsuario: registerData.codVendedor, telefono },
          (response) => {
            const updatedRegisterData = {
              ...registerData,
              celVendedor: telefono,
            };
            updateRegisterData(updatedRegisterData);

            navigate("/registro-vendedor/sms-validacion");
          },
          (error) => {
            console.error("Error al enviar el SMS:", error);
          }
        );
      } catch (error) {
        console.error("Error al enviar el SMS:", error);
      }
    } else {
      console.log("Teléfono no válido");
    }
  };

  return (
    <div className="flex flex-col pt-20 items-center bg-zinc-100">
      <div className="bg-white rounded-lg shadow-lg p-14 max-w-lg overflow-hidden">
        <div className="mb-4">
          <h1 className="text-2xl font-medium text-primario">
            Ingresa tu teléfono
          </h1>
          <p className="text-sm pt-4">
            Te enviaremos un código por SMS para validarlo. Con este teléfono
            podrás entrar a tu cuenta.
          </p>
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col">
              <label
                htmlFor="celVendedor"
                className="text-sm text-gray-500 mb-1 pl-2 mt-6"
              >
                Digite su teléfono
              </label>
              <input
                type="text"
                className={`border focus:outline-secundario p-3 rounded-lg ${
                  !isValidTelefono && "border-red-500"
                }`}
                value={telefono}
                onChange={handleTelefonoChange}
                pattern="[0-9]{10}"
                title="El número de teléfono debe tener 10 dígitos"
                required
              />
              {!isValidTelefono && (
                <p className="text-red-500 text-sm mt-1">
                  Por favor, ingrese un número de teléfono válido (10 dígitos).
                </p>
              )}
            </div>
            <button
              type="submit"
              className={`${
                !isValidTelefono ? "bg-gray-300" : "bg-secundario"
              } p-3 w-full text-white mt-4 rounded-lg text-base font-medium`}
              disabled={!isValidTelefono}
            >
              Enviar código por SMS
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};



export const MensajeSms = () => {
  const navigate = useNavigate();
  const { registerData, updateRegisterData } = useRegisterContext();
  const [verificationCode, setVerificationCode] = useState(["", "", "", ""]);

  const codeInputs = useRef([]);

  const handleVerificationCodeChange = (index, value) => {
    const updatedCode = [...verificationCode];
    updatedCode[index] = value;
    setVerificationCode(updatedCode);

    if (value && index < codeInputs.current.length - 1) {
      codeInputs.current[index + 1].focus();
    }
  };

  const handleVerificationCodeSubmit = async (e) => {
    e.preventDefault();

    const code = verificationCode.join("");


    try {
      const response = await validarSms(code, registerData.codVendedor, registerData.celVendedor );
      if (
        response &&
        response.status &&
        response.usuario &&
        response.usuario.codUsuario === registerData.codVendedor
      ) {
        toast.success("Teléfono agregado exitosamente");
        
        const updatedRegisterData = {
          ...registerData,
          celVendedorDespuesSms: registerData.celVendedor,
        };
        updateRegisterData(updatedRegisterData);
        setTimeout(() => {
          navigate("/registro-vendedor");
        }, 1500);
      } else {
        toast.error("Código de verificación incorrecto");
        resetVerificationCode();
      }
    } catch (error) {
      console.error("Error en la solicitud", error);
      if (error.response && error.response.data && error.response.data.message) {
        // Si el servidor devuelve un mensaje de error específico, mostrarlo
        toast.error(error.response.data.message);
      } else {
        // Si no, mostrar un mensaje genérico de error
        toast.error("Error al comunicarse con el servidor");
      }
      resetVerificationCode();
    }
  };

  const resetVerificationCode = () => {
    setVerificationCode(["", "", "", ""]);
    codeInputs.current.forEach((input) => {
      input.value = "";
    });
    codeInputs.current[0].focus();
  };

  return (
    <div className="flex flex-col pt-20 items-center bg-zinc-100">
      <div className="bg-white rounded-lg shadow-lg p-14 max-w-lg overflow-hidden">
        <div className="mb-4">
          <h2 className="text-xl font-medium">Verificación de código</h2>
          <p className="text-sm mt-2 pl-2">
            Ingresa el código de verificación que recibiste por SMS al +57{" "}
            {registerData.celVendedor}
          </p>
          <form onSubmit={handleVerificationCodeSubmit} className="mt-4">
            <div className="flex items-center justify-center">
              {verificationCode.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  className="border p-2 rounded-md mr-2 w-12 text-center mt-4"
                  value={digit}
                  onChange={(e) =>
                    handleVerificationCodeChange(index, e.target.value)
                  }
                  maxLength={1}
                  ref={(input) => (codeInputs.current[index] = input)}
                  required
                />
              ))}
            </div>
            <div className="flex justify-center mt-4">
              <button
                type="submit"
                className="bg-secundario p-3 text-white rounded text-base font-medium w-60"
              >
                Verificar
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer position="top-center" style={{ width: "500px" }} />
    </div>
  );
};



export const FormRegistro = () => {
  const { registerData, updateRegisterData } = useRegisterContext();

  const [listaDepartamentos, setListaDepartamentos] = useState([]);
  const [listaCiudades, setListaCiudades] = useState([]);
  const [razon, setRazon] = useState("");
  const [nit, setNit] = useState("");
  const [direccion, setDireccion] = useState("");
  const [codCiudad, setCodCiudad] = useState("");
  const [departamento, setDepartamento] = useState("");
  const [clave, setClave] = useState("");
  const [repetirClave, setRepetirClave] = useState("")
  const [rut, setRut] = useState("");
  const [formularioEnviado, setFormularioEnviado] = useState(false);
  const [terminos, setTerminos] = useState("");
  const [showClave, setShowClave] = useState(false)
  const [showRepetirClave, setShowRepetirClave] = useState(false)


  const navigate = useNavigate();

  useEffect(() => {
    const fetchDepartamentos = async () => {
      try {
        const isVendedor = true;
        const departamentos = await getDptos(isVendedor);
        setListaDepartamentos(departamentos);
      } catch (error) {
        console.error("Error al obtener los departamentos:", error);
      }
    };

    fetchDepartamentos();
  }, []);

  useEffect(() => {
    const fetchCiudades = async () => {
      try {
        if (departamento) {
          const municipios = await getCitys(departamento);
          setListaCiudades(municipios);
        } else {
          setListaCiudades([]);
        }
      } catch (error) {
        console.error("Error al obtener los municipios:", error);
      }
    };

    fetchCiudades();
  }, [departamento]);

  const handleContraseñaChange = (e) => {
    const { id, value } = e.target
    if (id === "clave") {
      setClave(value)
    } else if (id === "repetirClave") {
      setRepetirClave(value)
    }
  }

  const isValidClave = () => {
    if (clave.trim() === "") return false;
    const passwordRegex = /^(?=.*[A-Z])(?=.*[\W_])(?=.{8,})/;
    return passwordRegex.test(clave);
  };

  const isValidNit = (nitValue) => {
    const nitRegex = /^\d{6,11}$/;
    return nitRegex.test(nitValue);
  };

  const toggleShowClave = () => {
    setShowClave(!showClave)
  }
  const toggleShowRepetirClave = () => {
    setShowRepetirClave(!showRepetirClave)
  }

  const isClavesIguales = () => {
    return clave === repetirClave
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidClave()) {
      toast.error(
        "La contraseña debe contener al menos 8 caracteres, una mayúscula y un carácter especial."
      );
      return;
    }

    if (!isClavesIguales()) {
      toast.error("Las contraseñas no coinciden.")
      return
    }

    if (!isValidNit(nit)) {
      toast.error(
        "El NIT debe contener entre 6 a 11 digitos, incluyendo el codigo de verificación."
      );
      return;
    }

    try {
      const formData = new FormData();
      formData.append("codUsuario", registerData.codVendedor);
      formData.append("razon", razon);
      formData.append("nit", nit);
      formData.append("codCiudad", codCiudad);
      formData.append("direccion", direccion);
      formData.append("clave", clave);
      formData.append("rut", rut);

      await registroVendedor(formData); // Asegúrate de que 'registroVendedor' maneje FormData
      toast.success("Registro creado con éxito");
      updateRegisterData({
        ...registerData,
      indEstadoVend: 1
      });
      setFormularioEnviado(true);
      setTimeout(() => {
        navigate("/registro-vendedor/validacion-cuenta");
      }, 1000);
    } catch (error) {
      if (error.response && error.response.status === 408) {
        toast.error("El NIT ya se encuentra registrado en la base de datos");
      } else {
        console.error("Error al obtener los municipios:", error);
      }
    }
  };

  if (formularioEnviado) {
    return <ValidacionCuenta />;
  }

  return (
    <div className="flex flex-col pt-20 pb-5 items-center bg-zinc-100 overflow-y">
      <div className="bg-white rounded-lg shadow-lg p-14 max-w-2xl ">
        <div className="mb-4">
          <h1 className="text-2xl font-medium text-primario">
            Completa el formulario de registro
          </h1>
          <form onSubmit={handleSubmit} className="mt-4">
            <div className="mb-4 ">
              <label
                htmlFor="razon"
                className="text-sm text-gray-500 mb-1 pl-2 mt-6"
              >
                Razón Social
              </label>
              <input
                type="text"
                id="razon"
                className="border border-gray-300 rounded-md p-2 mt-1 w-full"
                value={razon}
                onChange={(e) => setRazon(e.target.value)}
                required
              />
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div className="mb-4 ">
                <div className="flex space-x-1 items-center ">
                  <label
                    htmlFor="nit"
                    className="text-sm text-gray-500 mb-1 pl-2"
                  >
                    NIT
                  </label>
                  <Tooltip
                    message={
                      "El NIT debe contener entre 6 a 11 digitos, incluyendo el codigo de verificación sin guión."
                    }
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="text-gray-400 h-3"
                    />
                  </Tooltip>
                </div>

                <input
                  type="number"
                  id="nit"
                  className="border border-gray-300 rounded-md p-2 mt-1 w-full"
                  value={nit}
                  onChange={(e) => setNit(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <div className="flex space-x-1 items-center ">
                  <label
                    htmlFor="rut"
                    className="text-sm text-gray-500 mb-1 pl-2 mt-1 block"
                  >
                    Adjuntar RUT
                  </label>
                  <Tooltip message={"Solo se admiten archivos pdf"}>
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="text-gray-400 h-3"
                    />
                  </Tooltip>
                </div>

                <div className="relative rounded-md overflow-hidden border border-gray-300 bg-white p-1  ">
                  <input
                    type="file"
                    id="rut"
                    name="rut"
                    accept=".pdf"
                    className="absolute inset-0 z-50 m-0 p-0 w-full h-full opacity-0 cursor-pointer"
                    onChange={(e) => setRut(e.target.files[0])}
                  />
                  <button className="absolute right-0 top-0 px-3 py-3 bg-gray-200 text-gray-700 text-xs font-medium">
                    Adjuntar
                  </button>
                  <p className="text-xs text-gray-600 p-2 truncate">
                    {rut ? rut.name : "No se ha seleccionado archivo"}
                  </p>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-2">
              <div className="mb-4">
                <label
                  htmlFor="departamento"
                  className="text-sm text-gray-500 mb-1 pl-2 mt-6"
                >
                  Departamento
                </label>

                <select
                  name="departamento"
                  id="departamento"
                  className="border border-gray-300 rounded-md p-3 mt-1 w-full text-sm"
                  value={departamento}
                  onChange={(e) => setDepartamento(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    --Seleccione--
                  </option>
                  {listaDepartamentos.map((departamento) => (
                    <option
                      key={departamento.codDepto}
                      value={departamento.codDepto}
                    >
                      {departamento.nomDepto}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="departamento"
                  className="text-sm text-gray-500 mb-1 pl-2 mt-6"
                >
                  Ciudad
                </label>

                <select
                  name="codCiudad"
                  id="codCiudad"
                  className="border border-gray-300 rounded-md p-3 mt-1 w-full text-sm"
                  value={codCiudad}
                  onChange={(e) => setCodCiudad(e.target.value)}
                  required
                >
                  <option value="" disabled>
                    --Seleccione--
                  </option>
                  {listaCiudades.map((ciudad) => (
                    <option key={ciudad.codCiudad} value={ciudad.codCiudad}>
                      {ciudad.nomCiudad}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="mb-4">
              <label
                htmlFor="direccion"
                className="text-sm text-gray-500 mb-1 pl-2 mt-6"
              >
                Dirección
              </label>
              <input
                type="text"
                id="direccion"
                className="border border-gray-300 rounded-md p-2 mt-1 w-full"
                value={direccion}
                onChange={(e) => setDireccion(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="clave"
                className="text-sm text-gray-500 mb-1 pl-2 mt-6"
              >
                Contraseña
              </label>
              <div className="relative">
                <input
                  type={showClave ? 'text' : 'password'}
                  id="clave"
                  className={`border rounded-md p-2 mt-1 w-full ${
                    clave.trim() === ""
                      ? "border-gray-300"
                      : !isValidClave()
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                  value={clave}
                  onChange={handleContraseñaChange}
                  required
                />
                <button
                  type="button"
                  onClick={toggleShowClave}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400"
                >
                  <FontAwesomeIcon icon={showClave ? faEyeSlash : faEye} />
                </button>
              </div>
              <p
                className={`text-sm mt-1 pl-2 ${
                  clave.trim() === ""
                    ? "text-gray-500"
                    : !isValidClave()
                    ? "text-red-500"
                    : "text-green-500"
                }`}
              >
                {clave.trim() === ""
                  ? "La contraseña debe contener al menos 8 caracteres, una mayúscula y un carácter especial."
                  : isValidClave()
                  ? "La contraseña cumple con los requisitos."
                  : "La contraseña debe contener al menos 8 caracteres, una mayúscula y un carácter especial."}
              </p>
            </div>
            <div className="mb-4">
              <label
                htmlFor="repetirClave"
                className="text-sm text-gray-500 mb-1 pl-2 mt-6"
              >
                Repetir Contraseña
              </label>
              <div className="relative">
              <input
                type={showRepetirClave ? 'text' : 'password'}
                id="repetirClave"
                className={`border rounded-md p-2 mt-1 w-full ${
                  repetirClave.trim() === ""
                    ? "border-gray-300"
                    : !isClavesIguales()
                    ? "border-red-500"
                    : "border-gray-300"
                }`}
                value={repetirClave}
                onChange={handleContraseñaChange}
                required
              />
              <button
                  type="button"
                  onClick={toggleShowRepetirClave}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400"
                >
                  <FontAwesomeIcon icon={showRepetirClave ? faEyeSlash : faEye} />
                </button>
              </div>
            
              <p
                className={`text-sm mt-1 pl-2 ${
                  repetirClave.trim() === ""
                    ? "text-gray-500"
                    : !isClavesIguales()
                    ? "text-red-500"
                    : "text-green-500"
                }`}
              >
                {repetirClave.trim() === ""
                  ? "Por favor, repita la contraseña."
                  : isClavesIguales()
                  ? "Las contraseñas coinciden."
                  : "Las contraseñas no coinciden."}
              </p>
            </div>
            <div className="mb-4">
              <label className="flex items-center text-sm text-gray-500 mb-1 pl-2 mt-6">
                <input
                  type="checkbox"
                  className="mr-2"
                  required
                  id="indAceptaterminos"
                  value={terminos}
                  onChange={(e) => setTerminos(e.target.value)}
                />
                Acepto los
                <a
                  href="#"
                  className="text-primary underline ml-2 mr-2 hover:text-secundario"
                >
                  términos y condiciones
                </a>
                y el
                <a
                  href="#"
                  className="text-primary underline ml-2 hover:text-secundario"
                >
                  tratamiento de datos personales
                </a>
              </label>
            </div>

            <button
              type="submit"
              className="bg-secundario p-3 text-white rounded-lg text-base font-medium w-full"
            >
              {" "}
              Enviar
            </button>
          </form>
        </div>
        <ToastContainer position="top-center" style={{ width: "500px" }} />
      </div>
    </div>
  )
};




export const ValidacionCuenta = () => {
  const { registerData} = useRegisterContext();

  return (
    <div className="flex flex-col pt-20 items-center bg-zinc-100 ">
      <div className="bg-white rounded-lg shadow-lg p-14 max-w-lg overflow-hidden">
        <div className="mb-4">
          <h1 className="text-2xl font-medium text-primario">
            Registro exitoso
          </h1>
          <p className="text-sm pt-4">
            Te has registrado con éxito. La habilitación a la plataforma será
            notificada en un plazo máximo de 24 horas, a través del correo{" "}
            {registerData.emaVendedor}, para que puedas empezar a vender!!
          </p>
          <div className="flex justify-center">
            <FontAwesomeIcon
              icon={faCircleCheck}
              className="text-secundario h-16 p-4 mt-4"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegistroVendedor
