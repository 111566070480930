import axios from "axios";

const baseURL = "https://devshopapi.encarga.app";

export const obtenerPedidosPorCliente= async (codCliente) => {
  const options = {
    method: "GET",
    url: `${baseURL}/pedidos/${codCliente}`,
    headers: {
      // Authorization: getToken(),
    },
  };

  try {
    const response = await axios(options);
    return response.data; 
  } catch (error) {
    console.error("Error en la solicitud:", error);
    throw error; 
  }
};

export const getOrderById =  async (numPedido) => {
  const options = {
    method: "GET",
    url: `${baseURL}/pedido/${numPedido}`, 
    headers: {}
  };

  try {
    const response = await axios (options)
    return response.data

  } catch(error){
    console.error("request error", error)
    throw error
  }
}


export const getStateOrders = async () => {
  const options = {
    method: 'GET',
    ulr: `${baseURL}/estados/pedidos`,
    headers:{}
  }

  try {
    const response = await axios (options)
    return response.data

  } catch (error){
    console.log("request error: ", error)
    throw error
  }

  
}