import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SideBarProductos from "../../components/SideBarProductos";
import { Card } from "../../components/CardSlider";
import { obtenerProductosPorCategoria } from "../../utils/apiProductos";

export const ProductsByCategory = () => {
  const { codCategory } = useParams();

  const [productos, setProductos] = useState([]);
  const [nomCategoria, setNomCategoria] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await obtenerProductosPorCategoria(codCategory);
        setProductos(data.productos);
        setNomCategoria(
          data.productos.length > 0
            ? data.productos[0].categoriaPrincipal.nomCategoria
            : ""
        );
      } catch (error) {
        console.error("Error en la solicitud:", error);
      }
    };
    fetchData();
  }, [codCategory]);

  const setFilteredProducts = (filteredProducts) => {
    setProductos(filteredProducts);
  };

  return (
    <div className="flex flex-col md:flex-row mt-16 mx-10 h-dvh 2xl:px-20">
      <SideBarProductos setFilteredProducts={setFilteredProducts} />

      <div className="flex flex-col md:pl-6 w-5/6">
      <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
      {productos &&
            productos.map((producto) => (
              <Card
                key={producto.id}
                imagen={producto.imagen}
                precioProducto={producto.precioProducto}
                precioRebajado={producto.precioRebajado}
                nomProducto={producto.nomProducto}
                codProducto={producto.codProducto}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
