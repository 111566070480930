import { formatPrecio } from "../../helpers/formatPrecio"

export const ResumenPedidos = ({
  cantidadTotal,
  subTotal,
  costoEnvio,
  total,
  title,
}) => {
  return (
    <div className=" border-b mt-4">
      <h2 className="font-bold uppercase text-primario mb-6 text-lg">
        {title}
      </h2>
      <table className="w-full mb-8 text-sm">
        <tbody>
          <tr>
            <td>{cantidadTotal} Productos </td>
            <td className="text-right">{formatPrecio(subTotal)}</td>
          </tr>
          {}
          <tr>
            <td>Costo Envio</td>
            <td className="text-right">{formatPrecio(costoEnvio)}</td>
          </tr>
          <tr>
            <td>Costo total</td>
            <td className="text-right">{formatPrecio(total)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
