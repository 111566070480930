import axios from "axios";

const baseURL = "https://devshopapi.encarga.app"

export const obtenerVendedores = async () => {
    const options = {
      method: "GET",
      url: `${baseURL}/vendedores`,
      headers: {
        // Authorization: getToken(),
      },
    }
  
    try {
      const response = await axios(options)
      return response.data
    } catch (error) {
      console.error("Error en la solicitud:", error)
      throw error
    }
  }