// src/Tabs.js
import React, { useState } from "react"

import Valoraciones from "./Valoraciones"

const TabsProductoSimple = ({ producto}) => {
  const [activeTab, setActiveTab] = useState(1)
  const [valoraciones, setValoraciones] = useState(0) // eliminar esta linea cuando se integre con la api

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber)
  }

  return (
    <div className="max-w-3xl mt-8">
      <div className="flex">
        <button
          className={`py-2 px-4 ${
            activeTab === 1 ? "bg-primario text-white " : "bg-gray-200"
          }`}
          onClick={() => handleTabClick(1)}
        >
          Descripción
        </button>
        <button
          className={`py-2 px-4 ml-2 ${
            activeTab === 2 ? "bg-primario text-white" : "bg-gray-200"
          }`}
          onClick={() => handleTabClick(2)}
        >
         {`Valoraciones (${valoraciones})`} {/* Cambiar cuando se integre con la API */}
        </button>
      </div>
      <div className="mt-4">
        {activeTab === 1 && (
          <p className="ml-2 text-justify text-sm">{producto.desProducto}</p>
        )}
        {activeTab === 2 && <Valoraciones producto={producto} />}
      </div>
    </div>
  )
}

export default TabsProductoSimple
