import React, { useEffect, useState } from "react"

import { obtenerProductosGeneral } from "../utils/apiProductos"

export const useFetchProductos = () => {
  const [productos, setProductos] = useState([])
  const [ofertas, setOfertas] = useState([])

  useEffect(() => {
    
    const getProducts = async () => {
      try {
        const products = await obtenerProductosGeneral()

        const productosConOferta = products.filter(
          (producto) =>
            producto.precioRebajado && producto.precioRebajado.trim() !== ""
        )
        setProductos(products)
        setOfertas(productosConOferta)
      } catch (error) {
        console.error("Error al obtener los productos", error)
      }
    }
    getProducts()
  }, [])
  return {
    productos,
    ofertas,
  }
}
