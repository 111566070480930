import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"

import Banner from "./Banner"
import CardSlider from "../../components/CardSlider"
import { obtenerProductosGeneral } from "../../utils/apiProductos"
import Categorias from "../../components/Categorias"
import Breadcrumbs from "../../components/Breadcrumbs"
import { useFetchProductos } from "../../hooks/useFetchProducts"
import { CategoryCard } from "../../components/CategoryCard"
import { useFetchCategorias } from "../../hooks/useFetchCategorias"
import accesorios from "../../media/categorias/accesorios_category.webp"
import food from "../../media/categorias/food.jpg"
import medicamentos from "../../media/categorias/medicamentos.jpg"
import encargaPET from "../../media/portafolio/encargaPET.png"
import { IconWithText } from "../../components/IconWithText"
import {
  faBox,
  faLocationDot,
  faShippingFast,
  faStar,
  faTags,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Home = () => {
  const { productos, ofertas } = useFetchProductos()

  const listaCategorias = useFetchCategorias()

  const categoriasPET = listaCategorias.filter(
    (categoria) => categoria.codPadre === "47"
  )

  return (
    <div className="w-full min-h-screen border-b-2">
      <Banner />
      <div className="px-10 py-8 md:mx-8 ">
        <Breadcrumbs />
        {/* <div className="flex md:flex-row md:space-x-8 justify-center">
          {categoriasPET.map((categoria) => (
            <CategoryCard key={categoria.codCategoria} categoria={categoria} />
          ))}
        </div> */}
        <div className="flex flex-col md:flex-row bg-fondoGris rounded-3xl md:p-3  justify-around mb-6 md:mb-6 ">
          <IconWithText
            icon={faBox}
            text1="Amplia y diversa selección"
            text2="de productos."
          />
          <IconWithText
            icon={faShippingFast}
            text1="Envíos rápidos, seguros  "
            text2="y confiables a tu puerta."
          />
          <IconWithText
            icon={faLocationDot}
            text1="Servicio de entrega en"
            text2="toda la ciudad de Bogotá."
          />
          <IconWithText
            icon={faStar}
            text1="Productos de "
            text2="alta calidad"
          />
          <IconWithText
            icon={faTags}
            text1="Descuentos exclusivos y"
            text2="promociones especiales."
          />
        </div>
        <div className="flex pt-4">
          <img src={encargaPET} alt="Encarga PET" className="h-20 mb-10" />
        </div>
        <div className="flex flex-col md:flex-row md:space-x-8 justify-center">
          {categoriasPET.length > 0 && (
            <>
              <CategoryCard
                key={categoriasPET[0].codCategoria}
                categoria={categoriasPET[0]}
                imagen={accesorios}
              />
              <CategoryCard
                key={categoriasPET[1].codCategoria}
                categoria={categoriasPET[1]}
                imagen={food}
              />
              <CategoryCard
                key={categoriasPET[2].codCategoria}
                categoria={categoriasPET[2]}
                imagen={medicamentos}
              />
            </>
          )}
        </div>

        <div className="flex space-x-4 p-3 text-primario font-bold items-end">
          <h1 className="text-xl md:text-2xl ">OFERTAS</h1>
          <Link className="text-lg text-secundario">Ver Todas</Link>
        </div>
        <CardSlider columns={4} productos={ofertas} />
        <div className="flex space-x-4 p-3 text-primario font-bold items-end mt-20">
          <h1 className="text-xl md:text-2xl ">COMPRA POR CATEGORIAS</h1>
          <Link className="text-lg text-secundario">Ver Todas</Link>
        </div>
        <CardSlider columns={4} productos={productos} />
      </div>
    </div>
  )
}

export default Home
