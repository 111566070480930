import React, { useEffect, useState } from "react";

const Atributos = ({ producto, onAtributosChange }) => {
  const [selectedTerminos, setSelectedTerminos] = useState([]);

  const handleTerminoClick = (atributo, termino) => {
    setSelectedTerminos((prevSelectedTerminos) => {
      const newSelectedTerminos = {
        [atributo.codAtributo]: termino,
      };
  
      // Filtrar términos seleccionados anteriores que pertenecen a otros productos
      const filteredPrevSelectedTerminos = Object.keys(prevSelectedTerminos)
        .filter((codAtributo) => {
          const atributoProducto = producto.atributos.find((attr) => attr.codAtributo === codAtributo);
          return atributoProducto && atributoProducto.codProducto === atributo.codProducto;
        })
        .reduce((filtered, codAtributo) => {
          filtered[codAtributo] = prevSelectedTerminos[codAtributo];
          return filtered;
        }, {});
  
      return {
        ...filteredPrevSelectedTerminos,
        ...newSelectedTerminos,
      };
    });
  };
  
  

  useEffect(() => {
    onAtributosChange(selectedTerminos);
  }, [selectedTerminos, onAtributosChange]);


  
  return (
    <div className="mt-6">
      {producto.atributos &&
        producto.atributos.map((atributo) => (
          <div key={atributo.codAtributo} className="flex flex-col w-1/3 space-y-2">
            <label htmlFor={`select-${atributo.codAtributo}`} className="mt-4">
              {atributo.nomAtributo}
            </label>
            <div className="flex ">
              {atributo.terminos.map((termino) => (
                <button
                  key={termino.codTermino}
                  className={`border border-gray-200  p-2 ${selectedTerminos[atributo.codAtributo] === termino ? 'bg-blue-500 text-white' : ''}`}
                  onClick={() => handleTerminoClick(atributo, termino)}
                >
                  {termino.nomTermino}
                </button>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default Atributos;
