import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"

export const IconWithText = ({ icon, text1, text2 }) => {
  return (
    <div className="flex space-x-4 items-center py-3.5 md:py-0 px-8 md:px-0 md:justify-center ">
      <FontAwesomeIcon
        icon={icon}
        className=" text-grisClaro hover:text-black h-7 w-7"
      />
      <div className="">
        <span className="text-azulTitulos hover:text-black text-13px">
          {text1}
        </span>
        <p className="text-azulTitulos hover:text-black text-13px">{text2}</p>
      </div>
    </div>
  )
}
