import axios from "axios";

const baseURL = "https://devshopapi.encarga.app"
// const getToken = () => {
//   return `Bearer ${localStorage.getItem('token')}`
  
// };

export const registroEmail = async (data) => {
  
    const options = {
      method: "POST",
      url: `${baseURL}/registro/vendedor/email`,
      headers: {
        //  Authorization: getToken(),
      },
      data
    };
  
    try {
      const response = await axios.request(options);
      return response.data; // Retornar la respuesta del servidor
    } catch (error) {
      if (error.response && error.response.data) {
        return error.response.data; // Manejar el error y obtener la respuesta del servidor
      } else {
        throw error;
      }
    }
  };
  
  

  export const registroTelefono = async (data, successCallback, errorCallback) => {
    const options = {
      method: "POST",
      url: `${baseURL}/registro/vendedor/enviarsms`,
      headers: {
        // Authorization: getToken(),
      },
      data,
    };
  
    try {
      const response = await axios.request(options);
      successCallback(response);
    } catch (error) {
      errorCallback(error);
    }
  };
  

  export const validarSms = async (pin, codUsuario, telefono) => {
    const data = {
      pin: pin, 
      codUsuario: codUsuario,
      telefono: telefono
    };
  
    const options = {
      method: "POST",
      url: `${baseURL}/registro/vendedor/validarsms`,
      headers: {
        //  Authorization: getToken(),
  
      },
      data
    };
    const response = await axios.request(options);
    return response.data; 
  };




  export const registroVendedor = async (data) => {
    const options = {
      method: "POST",
      url: `${baseURL}/registro/vendedor`,
      headers: {
        // Agrega cualquier encabezado necesario, como Authorization
      },
      data
    };
  
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      throw error;
    }
  };


  export const login= async (data) => {
    const options = {
      method: "POST",
      url: `${baseURL}/login`,
      headers: {
        // Agrega cualquier encabezado necesario, como Authorization
      },
      data
    };
  
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      throw error;
    }
  };


  


  
  
