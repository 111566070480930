import React from "react"
import { Link } from "react-router-dom"

import logo from "../media/logoEncarga.png"
import favicon from "../media/favicon.png"

const TopbarRegistro = () => {
  return (
    <div className="bg-terciario flex items-center justify-between h-20 ">
      <div className="">
      <Link to="/">
        <img
          className="object-contain w-40 hidden md:block mx-4"
          src={logo}
          alt="logo"
        />
        </Link>
        <img className="h-10 block mx-4  md:hidden " src={favicon} alt="logo" />
      </div>
      <Link to="/">
        <div className="flex flex-col mx-3 text-white pr-4">
          <span className="text-xs">Regresar</span>
          <span className="text-sm font-extrabold">a la tienda</span>
        </div>
      </Link>
    </div>
  )
}

export default TopbarRegistro
