import React, { useContext, useState, useEffect } from "react"

import { getDptos, getCitys } from "../utils/apiCiudades"

const ModalUbicacion = ({ isOpen, onClose, setDepartamento, setCiudad }) => {
  const [listaDepartamentos, setListaDepartamentos] = useState([])
  const [listaCiudades, setListaCiudades] = useState([])
  const [selectedDepartamento, setSelectedDepartamento] = useState("")
  const [selectedCiudad, setSelectedCiudad] = useState("")
  const [direccion, setDireccion] = useState("")

  useEffect(() => {
    const fetchDepartamentos = async () => {
      try {
        const departamentos = await getDptos()
        setListaDepartamentos(departamentos)
      } catch (error) {
        console.error("Error al obtener los departamentos:", error)
      }
    }

    fetchDepartamentos()
  }, [])

  useEffect(() => {
    const fetchCiudades = async () => {
      try {
        if (selectedDepartamento) {
          const municipios = await getCitys(selectedDepartamento)
          setListaCiudades(municipios)
        } else {
          setListaCiudades([])
        }
      } catch (error) {
        console.error("Error al obtener los municipios:", error)
      }
    }

    fetchCiudades()
  }, [selectedDepartamento])

  const handleDepartamentoChange = (e) => {
    setSelectedDepartamento(e.target.value)
    setSelectedCiudad("") // Reset ciudad when departamento changes
  }

  const handleCiudadChange = (e) => {
    setSelectedCiudad(e.target.value)
  }

  const handleSubmit = () => {
    setDepartamento(selectedDepartamento)
    setCiudad(selectedCiudad)
    onClose()
  }

  if (!isOpen) return null

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto w-2/5">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex justify-between py-3 px-8 border-b border-solid border-slate-200 rounded-t items-center">
              <h3 className="text-xl font-semibold pt-3">
                Elige dónde recibir tus compras{" "}
              </h3>
            </div>
            <div className="relative p-8 flex-auto text-sm">
              <div className="grid grid-cols-2 space-x-6 ">
                <div className="col-span-1">
                  <label
                    htmlFor="departamento"
                    className="text-sm text-gray-500 mb-1 pl-2 mt-6"
                  >
                    Departamento
                  </label>
                  <select
                    name="departamento"
                    id="departamento"
                    className="border border-gray-300 rounded-md p-3 mt-1 w-full text-sm"
                    value={selectedDepartamento}
                    onChange={handleDepartamentoChange}
                  >
                    <option value="" disabled>
                      --Seleccione--
                    </option>
                    {listaDepartamentos.map((departamento) => (
                      <option
                        key={departamento.codDepto}
                        value={departamento.codDepto}
                      >
                        {departamento.nomDepto}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-span-1">
                  <label
                    htmlFor="ciudad"
                    className="text-sm text-gray-500 mb-1 pl-2 mt-6"
                  >
                    Ciudad
                  </label>

                  <select
                    name="ciudad"
                    id="ciudad"
                    className="border border-gray-300 rounded-md p-3 mt-1 w-full text-sm"
                    value={selectedCiudad}
                    onChange={handleCiudadChange}
                  >
                    <option value="" disabled>
                      --Seleccione--
                    </option>
                    {listaCiudades.map((ciudad) => (
                      <option key={ciudad.codCiudad} value={ciudad.codCiudad}>
                        {ciudad.nomCiudad}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="mt-4">
              <label
                htmlFor="direccion"
                className="text-sm text-gray-500 mb-1 pl-2 mt-6"
              >
                Dirección
              </label>
              <input
                type="text"
                id="direccion"
                className="border border-gray-300 rounded-md p-3 mt-1 w-full"
                value={direccion}
                onChange={(e) => setDireccion(e.target.value)}
                required
              />
            </div>
            </div>
            <div className="flex items-center justify-end p-3 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={onClose}
              >
                Cerrar
              </button>
              <button
                className="bg-secundario text-white active:bg-secundario font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleSubmit}
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

export default ModalUbicacion
