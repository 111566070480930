import React from 'react';

const Categorias = ({ listaCategorias, handleCategoriaChange }) => {
  const renderCategoria = (categoria, nivel = 0) => {
    const paddingLeft = nivel * 10; // Ajusta el nivel de tabulado según tus necesidades

    return (
      <div key={categoria.codCategoria} className="flex items-center mb-2" style={{ paddingLeft }}>
        <input
          type="checkbox"
          id={`categoria-${categoria.codCategoria}`}
          name={`categoria-${categoria.codCategoria}`}
          value={categoria.codCategoria}
          onChange={handleCategoriaChange}
          className="mr-2"
        />
        <label htmlFor={`categoria-${categoria.codCategoria}`} className="text-sm">
          {categoria.nomCategoria}
        </label>
      </div>
    );
  };

  const renderCategorias = (categorias, nivel = 0) => {
    return categorias.map((categoria) => {
      const categoriasHijas = listaCategorias.filter((c) => c.codPadre === categoria.codCategoria);

      return (
        <React.Fragment key={categoria.codCategoria}>
          {renderCategoria(categoria, nivel)}
          {categoriasHijas.length > 0 && (
            <div className="ml-2">
              {renderCategorias(categoriasHijas, nivel + 1)}
            </div>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <div className="bg-white border rounded-lg shadow mb-5 w-full">
      <h1 className="border border-b-2 p-3 font-bold">Categorías del producto</h1>
      <div className="p-4">
        {renderCategorias(listaCategorias)}
      </div>
    </div>
  );
};

export default Categorias;
