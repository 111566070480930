import React, { useState, useEffect } from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import Layout from "./layouts/Layout"
import Home from "./pages/home/Home"
import Login from "./pages/usuarios/Login"
import LayoutRegistro from "./layouts/LayoutRegistro"
import RegistroVendedor, {
  FormRegistro,
  Telefono,
  Email,
  MensajeSms,
  ValidacionCuenta,
} from "./pages/usuarios/RegistroVendedor"
import RegistroUsuario, {
  EmailUsuario,
  FormRegistroUsuario,
  MensajeSmsUsuario,
  TelefonoUsuario,
  ValidacionCuentaUsuario,
} from "./pages/usuarios/RegistroUsuario"
import SeleccionRegistro from "./pages/usuarios/SeleccionRegistro"
import { RegisterProvider } from "./contexts/RegisterContext"
import { RegisterVendProvider } from "./contexts/RegisterVendContext"
import OlvidoContraseña from "./pages/usuarios/OlvidoContraseña"
import ProductoSimple from "./pages/productos/ProductoSimple"
import Cart from "./pages/compras/Cart"
import { CartProvider, useCartContext } from "./contexts/CartContext"
import { UserProvider } from "./contexts/UserContext"
import Checkout from "./pages/compras/Checkout"
import ResponseCompra from "./pages/compras/ResponseCompra"
import DetallesCuenta from "./pages/usuarios/cuenta/DetallesCuenta"
import Cuenta from "./pages/usuarios/cuenta/Cuenta"
import Pedidos from "./pages/usuarios/cuenta/pedidos/Pedidos"
import Perfil from "./pages/usuarios/cuenta/Perfil"
import DetallesPedidos from "./pages/usuarios/cuenta/pedidos/DetallesPedidos"
import { ProductsByCategory } from "./pages/productos/ProductsByCategory"
import { Productos } from "./pages/productos/Productos"
import { ProductsBySubcategory } from "./pages/productos/ProductsBySubcategory"
import { ProductsBySeller } from "./pages/productos/ProductsBySeller"

function App() {
  return (
    <BrowserRouter>
      <UserProvider>
        <RegisterProvider>
          <RegisterVendProvider>
            <CartProvider>
              <Routes>
                <Route path="" element={<Layout />}>
                  <Route path="/" element={<Home />} />
                  <Route path="cuenta" element={<Cuenta />}>
                    <Route path="perfil" element={<Perfil />} />
                    <Route
                      path="detalles-cuenta"
                      element={<DetallesCuenta />}
                    />
                    <Route path="pedidos" element={<Pedidos />} />
                  </Route>
                  <Route
                    path="pedido/:numPedido"
                    element={<DetallesPedidos />}
                  />
                  <Route path="productos" element={<Productos />} />
                  <Route path="producto/:id" element={<ProductoSimple />} />
                  <Route path="cart" element={<Cart />} />
                  <Route path="checkout" element={<CheckoutWithGuard />} />{" "}
                  <Route
                    path="response-compra"
                    element={<ResponseCompra />}
                  />
                  <Route
                    path="productos/category/:codCategory"
                    element={<ProductsByCategory/>}
                  />
                    <Route
                    path="category/:codCategory"
                    element={<ProductsByCategory/>}
                  />
                   <Route path="productos/category/:codCategory/:codSubcategory" element={<ProductsBySubcategory />} />
                   <Route path="productos/seller/:codVendedor" element={<ProductsBySeller />} />

                </Route>

                <Route path="" element={<LayoutRegistro />}>
                  <Route path="login" element={<Login />} />
                  <Route
                    path="olvido-contraseña"
                    element={<OlvidoContraseña />}
                  />
                  <Route
                    path="registro-seleccion"
                    element={<SeleccionRegistro />}
                  />

                  <Route
                    path="registro-vendedor"
                    element={<RegistroVendedor />}
                  />
                  <Route path="registro-vendedor/email" element={<Email />} />
                  <Route
                    path="registro-vendedor/telefono"
                    element={<Telefono />}
                  />
                  <Route
                    path="registro-vendedor/formulario"
                    element={<FormRegistro />}
                  />
                  <Route
                    path="registro-vendedor/sms-validacion"
                    element={<MensajeSms />}
                  />
                  <Route
                    path="registro-vendedor/validacion-cuenta"
                    element={<ValidacionCuenta />}
                  />
                  <Route
                    path="registro-usuario"
                    element={<RegistroUsuario />}
                  />
                  <Route
                    path="registro-usuario/email"
                    element={<EmailUsuario />}
                  />
                  <Route
                    path="registro-usuario/telefono"
                    element={<TelefonoUsuario />}
                  />
                  <Route
                    path="registro-usuario/sms-validacion"
                    element={<MensajeSmsUsuario />}
                  />
                  <Route
                    path="registro-usuario/formulario"
                    element={<FormRegistroUsuario />}
                  />
                  <Route
                    path="registro-usuario/validacion-cuenta"
                    element={<ValidacionCuentaUsuario />}
                  />
                </Route>
              </Routes>
            </CartProvider>
          </RegisterVendProvider>
        </RegisterProvider>
      </UserProvider>
    </BrowserRouter>
  )
}

function CheckoutWithGuard() {
  const { cart } = useCartContext()

  return cart.length > 0 ? <Checkout /> : <Navigate to="/" replace />
}

export default App
