import React, { useContext, useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBars,
  faLocationDot,
  faMagnifyingGlass,
  faShoppingCart,
  faXmark,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"

import logo from "../media/logoEncarga.png"
import logoPrueba from "../media/logoPrueba.png"
import favicon from "../media/favicon.png"
import ModalUbicacion from "./ModalUbicacion"
import { useUserContext } from "../contexts/UserContext"
import ShoppingCartIcon from "./ShoppingCartIcon"

const Topbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false) // Estado para controlar el modal
  const [departamento, setDepartamento] = useState("")
  const [listaDepartamentos, setListaDepartamentos] = useState([])
  const { isLoggedIn, setIsLoggedIn, logout, login } = useUserContext()

  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userData"))
  )

  useEffect(() => {
    if (userData) {
      login()
    }
  }, [userData])

  const handleMenuOpen = () => {
    setIsMenuOpen(true)
  }

  const handleMenuClose = () => {
    setIsMenuOpen(false)
  }
  const ubicacion =
    (userData && (userData.dirUsuario || userData.dirVendedor)) ||
    "Ingrese Ubicación"

  return (
    <div className="bg-primario bg-gradient-to-r from-blue-800 to-blue-500 flex justify-between sticky top-0 items-center h-15 z-[9999] py-2 ">
      <div className="flex">
        <button
          id="sidebar-open"
          className="flex px-3 items-center focus:outline-none md:hidden "
          type="button"
        >
          <FontAwesomeIcon icon={faBars} className="h-4 text-white" />
        </button>
        <Link to="/">
          <img
            className="object-contain w-24 md:w-32 md:mx-4 "
            src={logoPrueba}
            alt="logo"
          />
        </Link>
      </div>
      {isLoggedIn && (
        <div className="hidden md:flex flex-col mx-6 text-white justify-center">
          <span className="text-xs">Enviar a</span>
          <div>
            <Link onClick={() => setIsModalOpen(true)}>
              <FontAwesomeIcon icon={faLocationDot} className="-ml-5" />
              <span className="text-xs font-extrabold pl-2">{ubicacion}</span>
            </Link>
          </div>
        </div>
      )}

      <div className="hidden md:flex flex-1 items-center">
        <input className="h-10 p-1 border-none w-full rounded-l" type="text" />
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className="bg-secundario p-3 h-4 w-4 rounded-r cursor-pointer text-grisClaro hover:text-black"
        />
      </div>

      <div className="flex md:justify-evenly items-center md:px-0 md:pr-2">
        <div className="hidden md:flex flex-col md:mx-3 text-white cursor-pointer">
          {isLoggedIn ? (
            <div className="hidden md:flex mx-3">
              <div
                className="mx-3 text-white cursor-pointer relative "
                onMouseEnter={handleMenuOpen}
                onMouseLeave={handleMenuClose}
              >
                <div className="hidden md:flex flex-col ">
                  <span className="text-xs ">Hola</span>

                  {userData.nomUsuario ? (
                    <div className="space-x-2">
                      <span className="text-sm font-bold">
                        {userData.nomUsuario}
                      </span>
                      <FontAwesomeIcon icon={faChevronDown} className="h-3" />
                    </div>
                  ) : (
                    <span className="text-sm font-bold">
                      {userData.razonSocial}
                    </span>
                  )}
                </div>
                {isMenuOpen && (
                  <div className="absolute right-0 mt-0 py-2 bg-white border border-gray-200 rounded shadow-lg text-sm w-60">
                    <div
                      to="/"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200 border-b-2 items-center cursor-default"
                    >
                      <img
                        src={userData.imgUsuario}
                        className="h-10"
                        alt="Imagen de usuario"
                      />
                      <span className="text-xs">{userData.emaUsuario}</span>
                    </div>

                    <Link
                      to="/cuenta/perfil"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                    >
                      Perfil
                    </Link>
                    <Link
                      to="/cuenta/detalles-cuenta"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                    >
                      Detalles de la cuenta
                    </Link>

                    <span
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer border-t-2"
                      onClick={logout}
                    >
                      Salir
                    </span>
                  </div>
                )}
              </div>
              <Link to="cuenta/pedidos">
                <div className="hidden md:flex flex-col mx-3 justify-center">
                  <span className="text-xs">Devoluciones</span>
                  <span className="text-sm font-extrabold">y Pedidos</span>
                </div>
              </Link>
            </div>
          ) : (
            <div className="hidden md:flex text-sm font-bold">
              {" "}
              <Link to="/login">
                {" "}
                <span className="mx-4">Ingresa</span>
              </Link>
              <Link to="/registro-seleccion">
                <span>Crear Cuenta</span>
              </Link>
            </div>
          )}
        </div>
        <ShoppingCartIcon />
      </div>

      {isModalOpen && (
        <ModalUbicacion
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          setDepartamento={setDepartamento}
          setListaDepartamentos={setListaDepartamentos}
          departamento={departamento}
          listaDepartamentos={listaDepartamentos}
        />
      )}
    </div>
  )
}

export default Topbar
