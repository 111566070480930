import React, { useEffect } from "react"
import check from "../media/check.png"

const SuccessModal = ({ isOpen, onClose, message }) => {
  useEffect(() => {
    // Función para deshabilitar el scroll en el cuerpo de la página
    const disableBodyScroll = () => {
      document.body.style.overflow = "hidden"
    }

    // Función para habilitar el scroll en el cuerpo de la página
    const enableBodyScroll = () => {
      document.body.style.overflow = "auto"
    }

    // Habilitar/deshabilitar el scroll al abrir/cerrar el modal
    if (isOpen) {
      disableBodyScroll()
    } else {
      enableBodyScroll()
    }

    // Limpiar el efecto al desmontar el componente
    return () => enableBodyScroll()
  }, [isOpen])

  useEffect(() => {
    const handleBodyClick = (e) => {
      if (!e.target.closest(".modal-container") && isOpen) {
        onClose()
      }
    }

    if (isOpen) {
      document.body.addEventListener("mousedown", handleBodyClick)
    }

    return () => {
      document.body.removeEventListener("mousedown", handleBodyClick)
    }
  }, [isOpen, onClose])

  return (
    <>
      {isOpen && (
        <div>
          <div className="fixed inset-0 bg-black opacity-50 z-40 overlay"></div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto w-3/4 md:w-1/4">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none ">
                <div className="flex flex-col relative p-8 items-center text-sm">
                  <img src={check} alt="ok" className="h-16 w-16 mb-6" />
                  <h1 className="font-semibold">{message}</h1>
                </div>
                <div className="flex items-center justify-end p-3 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={onClose}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SuccessModal
