import React, { useState } from "react"

const Valoraciones = ({ producto }) => {
  const valoraciones = producto.valoraciones || [
    {
      idValoracion: 1,
      nomUsuario: "Usuario1",
      valoracion: "Excelente producto, muy satisfecho.",
      puntuacion: 5,
      fechaValoracion: "2021-01-01",
    },
    {
      idValoracion: 2,
      nomUsuario: "Usuario2",
      valoracion: "Buena relación calidad-precio.",
      puntuacion: 4,
      fechaValoracion: "2021-01-02",
    },
    {
      idValoracion: 3,
      nomUsuario: "Usuario3",
      valoracion: "Entrega rápida y en buen estado.",
      puntuacion: 5,
      fechaValoracion: "2021-01-03",
    },
  ]

  const [puntuacion, setPuntuacion] = useState(0)
  const [valoracion, setValoracion] = useState("")

  const handlePuntuacion = (nuevaPuntuacion) => {
    setPuntuacion(nuevaPuntuacion)
  }

  const handlevaloracion = (event) => {
    setValoracion(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    // Aquí puedes enviar los datos al backend, por ejemplo:
    console.log("Puntuación:", puntuacion)
    console.log("Valoración:", valoracion)
  }

  return (
    <div className="ml-2">
      {valoraciones.length > 0 && (
        <h1 className="text-sm mb-2 pt-2 font-bold">
          {valoraciones.length > 0 && (
            <h1 className="text-sm mb-2 pt-2 font-bold">
              {`${valoraciones.length} VALORACION${
                valoraciones.length !== 1 ? "ES" : ""
              } EN ${producto ? `"${producto.nomProducto}"` : "este producto"}`}
            </h1>
          )}
        </h1>
      )}

      {valoraciones.length > 0 ? (
        valoraciones.map((valoracion) => (
          <div key={valoracion.idValoracion} className="mt-2">
            <div>
              {[1, 2, 3, 4, 5].map((valor) => (
                <span
                  key={valor}
                  className={`text-xl ${
                    valor <= valoracion.puntuacion
                      ? "text-yellow-500"
                      : "text-gray-300"
                  }`}
                >
                  ★
                </span>
              ))}
            </div>
            <div className="flex space-x-3 text-sm ">
              <p className="font-bold mb-1">{valoracion.nomUsuario}</p>
              <p className="italic">{valoracion.fechaValoracion}</p>
            </div>
            <p className="text-xs">{valoracion.valoracion}</p>
          </div>
        ))
      ) : (
        <div className="text-sm mt-4">
          <h1 className="text-sm mb-2">VALORACIONES</h1>
          <p>No hay valoraciones aún.</p>
          <p className="mt-2 font-semibold">
            Sé el primero en valorar{" "}
            {producto ? `"${producto.nomProducto}"` : "este producto"}
          </p>
        </div>
      )}

      <form onSubmit={handleSubmit} className="mt-4">
        <h3 className="text-sm mb-2">Tu puntuación *</h3>
        {/* Input de valoración con estrellas */}
        <div>
          {[1, 2, 3, 4, 5].map((valor) => (
            <span
              key={valor}
              className={`cursor-pointer text-xl ${
                valor <= puntuacion ? "text-yellow-500" : "text-gray-300"
              }`}
              onClick={() => handlePuntuacion(valor)}
            >
              ★
            </span>
          ))}
        </div>

        <div className="mt-4">
          <h3 className="text-sm mb-2">Tu valoración *</h3>
          <textarea
            value={valoracion}
            onChange={handlevaloracion}
            className="w-full p-2 border rounded text-sm h-24"
            placeholder="Escribe tu valoración aquí..."
          />
        </div>

        <button
          type="submit"
          className="mt-4 bg-secundario text-white py-2 px-6 rounded cursor-pointer"
        >
          Enviar
        </button>
      </form>
    </div>
  )
}

export default Valoraciones
