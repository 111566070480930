import React, { useState, useEffect } from "react"

import { useUserContext } from "../../../contexts/UserContext"
import perfilImg from "../../../media/perfilImg.webp"
import {
  edicionUsuario,
} from "../../../utils/apiRegistroUsuario"
import SuccessModal from "../../../components/SuccessModal"
import { useFetchCitys } from "../../../hooks/useFetchCitys"
import { useFetchDptos } from "../../../hooks/useFetchDptos"
import { useFetchTipoPersona, useFetchTiposDocumentos } from "../../../hooks/useFetchTypeDocument"

const Perfil = () => {

  const { isLoggedIn } = useUserContext()
  const [userData] = useState(JSON.parse(localStorage.getItem("userData")))
  const [email] = useState(userData?.emaUsuario ?? "")
  const [nombre, setNombre] = useState(userData?.nomUsuario ?? "")
  const [apeUsuario, setApeUsuario] = useState(userData?.apeUsuario ?? "")
  const [idUsuario, setIdUsuario] = useState(userData?.idUsuario ?? "")
  const [celUsuario, setCelUsuario] = useState(userData?.celUsuario ?? "")
  const [direccion, setDireccion] = useState(userData?.dirUsuario ?? "")
  const [codCiudad, setCodCiudad] = useState(userData?.ciuUsuario ?? "")
  const [codDepto, setCodDepto] = useState(userData?.codDepto ?? "")
  const [codTipContribuyente, setCodTipContribuyente] = useState(
    userData?.codTipContribuyente ?? ""
  )
  const [codTipPersona, setCodTipPersona] = useState(
    userData?.codTipPersona ?? ""
  )
  const [imagen, setImagen] = useState("")
  const [imagenUrl, setImagenUrl] = useState(userData?.imgUsuario ?? perfilImg)
  const [showModal, setShowModal] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const listaCiudades = useFetchCitys(codDepto)
  const listaDepartamentos = useFetchDptos()
  const listaTipoDocumento = useFetchTiposDocumentos()
  const listaTipoPersona = useFetchTipoPersona()


  const handleImagenChange = (e) => {
    const files = e.target.files

    if (files.length > 0) {
      const file = files[0]
      setImagenUrl(URL.createObjectURL(file))
      setImagen(file)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const formData = new FormData()
      formData.append("emaCliente", email)
      formData.append("nomUsuario", nombre)
      formData.append("apeUsuario", apeUsuario)
      formData.append("idUsuario", idUsuario)
      formData.append("ciuUsuario", codCiudad)
      formData.append("dirUsuario", direccion)
      formData.append("celUsuario", celUsuario)
      formData.append("codTipPersona", codTipPersona)
      formData.append("codTipContribuyente", codTipContribuyente)
      formData.append("codUsuario", isLoggedIn ? userData.codUsuario : 0)
      formData.append("imagen", imagen)

      const response = await edicionUsuario(formData)

      localStorage.setItem("userData", JSON.stringify(response.datos))
      setIsSuccess(true)
    } catch (error) {
      console.error(error)
    }
  }


  const handleDeptoChange = (e) => {
    const newDepto = e.target.value;
    setCodDepto(newDepto);
    setCodCiudad("");
  };

  const handleCityChange = (e) => {
    const newCity = e.target.value;
    setCodCiudad(newCity);
  };


  return (
    <div className="pt-10 px-6 flex flex-col md:flex-row">
      <div className="md:mt-6 w-full justify-center md:w-1/6 md:mx-8  mb-8 md:mb-0 flex flex-col md:justify-start items-center">
        <img
          src={imagenUrl}
          alt="Imagen de usuario"
          className="rounded-full h-24 w-24"
        />
        <label
          className="cursor-pointer text-gray-500 text-sm pt-2"
          htmlFor="imagen"
        >
          Cambiar Imagen
        </label>

        <input
          type="file"
          className="hidden"
          accept=".webp, image/*"
          id="imagen"
          name="imagen"
          onChange={handleImagenChange}
        />
      </div>
      <div className="w-full md:w-5/6">
        <div className="mb-4 ">
          <div className="flex flex-col space-x-1 items-center md:items-start">
            <span className="text-sm text-gray-500 mb-1 ">
              Correo Electrónico:
            </span>
            <span className="font-bold text-sm mb-1 "> {email}</span>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="md:mr-20">
          <div className="grid md:grid-cols-2 gap-2">
            <div className="mb-4 ">
              <div className="flex space-x-1 items-center ">
                <label
                  htmlFor="nombre"
                  className="text-sm text-gray-500 mb-1 pl-2"
                >
                  Nombre
                </label>
              </div>

              <input
                type="text"
                id="nombre"
                className="border border-gray-300  p-2 mt-1 w-full"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                required
              />
            </div>
            <div className="mb-4 ">
              <div className="flex space-x-1 items-center ">
                <label
                  htmlFor="apeUsuario"
                  className="text-sm text-gray-500 mb-1 pl-2"
                >
                  Apellidos
                </label>
              </div>

              <input
                type="text"
                id="apeUsuario"
                className="border border-gray-300  p-2 mt-1 w-full"
                value={apeUsuario}
                onChange={(e) => setApeUsuario(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 gap-2">
            <div className="mb-4">
              <label
                htmlFor="celUsuario"
                className="text-sm text-gray-500 mb-1 pl-2 mt-6"
              >
                Teléfono
              </label>
              <input
                type="number"
                id="celUsuario"
                className="border border-gray-300  p-2 mt-1 w-full"
                value={celUsuario}
                onChange={(e) => setCelUsuario(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="direccion"
                className="text-sm text-gray-500 mb-1 pl-2 mt-6"
              >
                Dirección
              </label>
              <input
                type="text"
                id="direccion"
                className="border border-gray-300  p-2 mt-1 w-full"
                value={direccion}
                onChange={(e) => setDireccion(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 gap-2">
            <div className="mb-4">
              <label
                htmlFor="codDepto"
                className="text-sm text-gray-500 mb-1 pl-2 mt-6"
              >
                Departamento
              </label>

              <select
                name="codDepto"
                id="codDepto"
                className="border border-gray-300  p-3 mt-1 w-full text-sm"
                value={codDepto}
                onChange={handleDeptoChange}
                required
              >
                <option value="" disabled>
                  --Seleccione--
                </option>
                {listaDepartamentos.map((departamento) => (
                  <option
                    key={departamento.codDepto}
                    value={departamento.codDepto}
                  >
                    {departamento.nomDepto}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label
                htmlFor="codCiudad"
                className="text-sm text-gray-500 mb-1 pl-2 mt-6"
              >
                Ciudad
              </label>
              <select
                name="codCiudad"
                id="codCiudad"
                className="border border-gray-300  p-3 mt-1 w-full text-sm"
                value={codCiudad}
                onChange={handleCityChange}
                required
                disabled={!codDepto}
                >
                <option value="" disabled>
                  --Seleccione--
                </option>
                {listaCiudades.map((ciudad) => (
                  <option key={ciudad.codCiudad} value={ciudad.codCiudad}>
                    {ciudad.nomCiudad}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="grid md:grid-cols-2 gap-2">
            <div className="mb-4 ">
              <label
                htmlFor="codTipPersona"
                className="text-sm text-gray-500 mb-1 pl-2 mt-6"
              >
                Tipo de Persona
              </label>
              <select
                name="codTipPersona"
                id="codTipPersona"
                className="border border-gray-300  p-2 mt-1 w-full"
                value={codTipPersona}
                onChange={(e) => setCodTipPersona(e.target.value)}
                required
              >
                {listaTipoPersona.map((tipoPersona) => (
                  <option
                    key={tipoPersona.codTipPersona}
                    value={tipoPersona.codTipPersona}
                  >
                    {tipoPersona.nomTipPersona}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4 ">
              <label
                htmlFor="codTipContribuyente"
                className="text-sm text-gray-500 mb-1 pl-2 mt-6"
              >
                Tipo Identificación
              </label>
              <select
                name="codTipContribuyente"
                id="codTipContribuyente"
                className="border border-gray-300  p-2 mt-1 w-full"
                value={codTipContribuyente}
                onChange={(e) => setCodTipContribuyente(e.target.value)}
                required
              >
                {listaTipoDocumento.map((tipoDocumento) => (
                  <option
                    key={tipoDocumento.codTipo}
                    value={tipoDocumento.codTipo}
                  >
                    {tipoDocumento.nomTipo}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mb-4 ">
            <div className="flex space-x-1 items-center ">
              <label
                htmlFor="idUsuario"
                className="text-sm text-gray-500 mb-1 pl-2"
              >
                Nº de identificación
              </label>
            </div>
            <input
              type="number"
              id="idUsuario"
              className="border border-gray-300  p-2 mt-1 w-full"
              value={idUsuario}
              onChange={(e) => setIdUsuario(e.target.value)}
              required
            />
          </div>

          <div className="flex justify-center pb-5">
            <button
              type="submit"
              className="bg-secundario p-3 px-8 text-white rounded-lg text-sm font-medium mt-4 uppercase "
              onClick={() => setShowModal(true)}
            >
              Guardar los cambios
            </button>
          </div>
        </form>
        {isSuccess && (
          <SuccessModal
            isOpen={showModal}
            onClose={() => setShowModal(false)}
            message="Información Actualizada correctamente"
          />
        )}
      </div>
    </div>
  )
}
export default Perfil
