import { useEffect, useState } from "react"
import { obtenerProductosGeneral } from "../utils/apiProductos"

export const useFetchProductoById = (idProducto) => {
  const [producto, setProducto] = useState({})
  const [productosRelacionados, setProductosRelacionados] = useState([])

  useEffect(() => {
    const getProductById = async () => {
      try {
        const products = await obtenerProductosGeneral()

        const productoPorId = products.find(
          (producto) => producto.codProducto === idProducto
        )
        if (productoPorId) {
          setProducto(productoPorId)

          let categoriaRelacionada =
            productoPorId.categoriaSecundaria ||
            productoPorId.categoriaPrincipal

          let productosRelacionados = products.filter(
            (prod) =>
              prod.codProducto !== idProducto &&
              (categoriaRelacionada.codCategoria ===
                prod.categoriaSecundaria?.codCategoria ||
                categoriaRelacionada.codCategoria ===
                  prod.categoriaPrincipal?.codCategoria)
          )

          if (
            productosRelacionados.length === 0 &&
            productoPorId.categoriaSecundaria
          ) {
            categoriaRelacionada = productoPorId.categoriaPrincipal

            productosRelacionados = products.filter(
              (prod) =>
                prod.codProducto !== idProducto &&
                categoriaRelacionada.codCategoria ===
                  prod.categoriaPrincipal?.codCategoria
            )
          }

          setProductosRelacionados(productosRelacionados)
        }
      } catch (error) {
        console.error("Error al obtener el producto", error)
      }
    }
    getProductById()
  }, [idProducto])

  return {
    producto,
    productosRelacionados,
  }
}

