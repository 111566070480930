import { MD5 } from "crypto-js"


export const handlePayUPayment = async (numPedido, email, cartTotal, direccion, codCiudad) => {

  const apiKey = "8NlNpLqDgOxPf89Rafog237hi3"
  const merchantId = "949778"
  const accountId = "957370"
  const description = "Test PAYU"
  const tax = "0"
  const taxReturnBase = "0"
  const referenceCode = numPedido
  const amount = cartTotal
  const currency = "COP"
  const test = "1"
  const buyerEmail = email
  const responseUrl = "http://www.test.com/response"
  const confirmationUrl = "http://www.test.com/confirmation"
  const shippingAddress = direccion
  const shippingCity = codCiudad
  const shippingCountry = "CO"

  const encrypt = `${apiKey}~${merchantId}~${referenceCode}~${amount}~${currency}`

  const signature = MD5(encrypt).toString()

  const fields = {
    merchantId,
    accountId,
    description,
    referenceCode,
    tax,
    taxReturnBase,
    amount,
    currency,
    apiKey,
    test,
    buyerEmail,
    responseUrl,
    confirmationUrl,
    signature,
    shippingAddress, 
    shippingCity, 
    shippingCountry
    
  }

  const form = document.createElement("form")
  form.method = "POST"
  form.action = "https://checkout.payulatam.com/ppp-web-gateway-payu/"

  Object.keys(fields).forEach((fieldName) => {
    const field = document.createElement("input")
    field.type = "hidden"
    field.name = fieldName
    field.value = fields[fieldName]
    form.appendChild(field)
  })

  document.body.appendChild(form)
  form.submit()
}
