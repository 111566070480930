// Descuentos.js
import React from "react";
import { formatPrecio } from "../helpers/formatPrecio";


const Descuentos = ({ precioProducto, precioRebajado }) => {



  if (precioRebajado && precioRebajado < precioProducto) {
    const descuentoPorcentaje =
      ((precioProducto - precioRebajado) / precioProducto) * 100;


    return (
      <p className="text-white font-semibold">
        <span className="line-through"></span>
        {` ${descuentoPorcentaje.toFixed(0)}%`}
      </p>
    );
  }

  return null;
};

export default Descuentos;



