import React from "react"

import banner1 from "../../media/banner1.jpg"
import banner2 from "../../media/banner2.jpg"
import home2PET from "../../media/home2PET.jpg"
import Carousel from "../../components/Carousel"

const slides = [home2PET, banner1, banner2]

const Banner = () => {
  return (
    <div className="w-full">
      <Carousel>
        {slides.map((s, index) => (
          <img src={s} key={index} className="bg-cover w-full" />
        ))}
      </Carousel>
    </div>
  )
}

export default Banner
