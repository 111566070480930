import React from "react"
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom"

import { useUserContext } from "../../../contexts/UserContext"

const Cuenta = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const { logout, isLoggedIn } = useUserContext()

  const handleLogout = () => {
    logout()
    navigate("/")
  }

  if (!isLoggedIn) {
    // Si no está autenticado, redirige al usuario a la página de registro-selección
    return <Navigate to="/login" />
  }


  return (
    <div className="flex flex-col  min-h-screen ">
      <div className="md:flex md:flex-col pt-4 ">
      <ul className="flex justify-center border-b space-x-6">
          <li className="mb-4">
            <Link
              to="perfil"
              className={
                location.pathname.includes("/perfil")
                  ? "text-secundario font-bold"
                  : "text-primario"
              }
            >
              Perfil
            </Link>
          </li>
          <li className="mb-4">
            <Link
              to="pedidos"
              className={
                location.pathname.includes("/pedidos")
                  ? "text-secundario font-bold"
                  : "text-primario"
              }
            >
              Pedidos
            </Link>
          </li>
          <li className="mb-4">
            <Link
              to="detalles-cuenta"
              className={
                location.pathname.includes("/detalles-cuenta")
                  ? "text-secundario font-bold"
                  : "text-primario"
              }
            >
              Detalles de Cuenta
            </Link>
          </li>
          <li>
            <span
              className="text-primario cursor-pointer"
              onClick={handleLogout}
            >
              {" "}
              Salir
            </span>
          </li>
        </ul>
      
      </div>

      {/* Contenido principal */}
      <div className="bg-white">
        <Outlet />
      </div>
    </div>
  )
}

export default Cuenta
