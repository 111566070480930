import React, { useEffect, useState } from "react"
import { useCartContext } from "../../contexts/CartContext"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTicket } from "@fortawesome/free-solid-svg-icons"
import { formatPrecio } from "../../helpers/formatPrecio"
import { ResumenPedidos } from "../../components/compras/ResumenPedidos"
import { MetodosPago } from "./MetodosPago"

const Cart = () => {
  const {
    cart,
    cartTotal,
    removeItem,
    cartTotalItems,
    updateItemQuantity,
    cartTotalSinDescuento,
    cartTotalConDescuento,
  } = useCartContext()

  const [userData] = useState(JSON.parse(localStorage.getItem("userData")))

 

  return (
    <div className="mt-20 mx-6 md:ml-16 h-screen">
      {cart && cart.length > 0 ? (
        <div className="flex flex-col md:flex-row mt-10">
          <div className="w-full md:w-2/3 flex flex-col ">
            <h1 className="text-2xl font-bold text-primario  mb-2 uppercase">
              TU CARRITO
            </h1>
            <p>
              ({cartTotalItems} Productos) SUBTOTAL :
              <span className="font-bold">
                {formatPrecio(cartTotalConDescuento)}
              </span>
            </p>

            <p className="text-sm mt-2 mb-6">
              Los artículos en tu carrito no están reservados. Termina el
              proceso de compra ahora para quedarte con ellos.
            </p>
            <table className="text-xs md:text-base border-collapse md:mx-10 mb-6">
              <tbody>
                {cart.map((item, index) => (
                  <tr key={index} className="border-b">
                    <td className="md:p-2">
                      <button
                        className="text-red-500"
                        onClick={() => removeItem(item)}
                      >
                        X
                      </button>
                    </td>
                    <td className="hidden md:flex p-2">
                      <img className="w-24 h-24" src={item.imagen} alt="" />
                    </td>

                    <td className="p-2">
                      <div className="flex items-center flex-col md:flex-row">
                        <h2 className="ml-4 underline mb-2 md:mb-0 md:mr-4 w-full md:w-auto">
                          <Link to={"/producto/" + item.codProducto}>
                            {item.nomProducto}
                          </Link>
                          {item.atributos &&
                            item.atributos.map((atributo) => (
                              <span key={atributo.codAtributo}>
                                - {atributo.nomTermino}
                              </span>
                            ))}
                        </h2>
                      </div>
                    </td>

                    <td className="pl-1 md:pr-8 text-right w-1/4 md:w-1/5">
                      {formatPrecio(item.precioRebajado || item.precioProducto)}
                    </td>

                    <td className="p-2">
                      <select
                        className="border rounded p-2"
                        name="cantidad"
                        id="cantidad"
                        value={item.cantidad}
                        onChange={(e) =>
                          updateItemQuantity(
                            item.codProducto,
                            parseInt(e.target.value, 10)
                          )
                        }
                      >
                        {[...Array(10).keys()].map((value) => (
                          <option key={value + 1} value={value + 1}>
                            {value + 1}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="w-full md:w-1/3 md:px-10 mr-16 ">
            <Link to={"/checkout"}>
              <button
                className="bg-secundario hover:bg-secundario-700 text-white font-bold py-3 px-4 rounded w-full"
              >
                Finalizar compra
              </button>
            </Link>

            <div className="mt-12">
              <h2 className="font-bold uppercase text-primario mb-6 text-lg">
                Resumen del pedido
              </h2>

              <table className="w-full">
                <tbody>
                  <tr>
                    <td>{cartTotalItems} Productos</td>
                  </tr>
                  <tr>
                    <td>Precio Original</td>
                    <td className="text-right">
                      {formatPrecio(cartTotalSinDescuento)}
                    </td>
                  </tr>
                  <tr>
                    <td>Descuento</td>
                    <td className="text-right">
                      {formatPrecio(
                        cartTotalSinDescuento - cartTotalConDescuento
                      )}
                    </td>
                  </tr>

                  <tr className="font-bold mt-6">
                    <td className="">Subtotal</td>
                    <td className="text-right">
                      {formatPrecio(cartTotalConDescuento)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="mt-20 uppercase text-xs font-bold text-primario">
              <h3> Formas de pago aceptadas</h3>
              <MetodosPago />
            </div>
          </div>
        </div>
      ) : (
        <p>El carrito está vacío.</p>
      )}
    </div>
  )
}

export default Cart
