import axios from "axios";

const baseURL = "https://devshopapi.encarga.app";


export const realizarPedido = async (data) => {
    const options = {
      method: "POST",
      url: `${baseURL}/pedido`,
      headers: {
        
      },
      data
    };
  
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      throw error;
    }
  };