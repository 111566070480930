import { useLocation, Link } from 'react-router-dom';

const Breadcrumbs = () => {

  const location = useLocation();
  const paths = location.pathname.split('/').filter(path => path !== '');

  return (
    <div>
    {paths.map((path, index) => (
      <span key={path}>
        <Link to={`/${path}`}>{path}</Link>
        {index < paths.length - 1 && ' > '}
      </span>
    ))}
  </div>
  )
}

export default Breadcrumbs