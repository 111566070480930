import React, { useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart } from "@fortawesome/free-solid-svg-icons"
import { faHeart as heartRegular } from "@fortawesome/free-regular-svg-icons"
import { Link } from "react-router-dom"
import CustomArrow from "./CustomArrow"
import Descuentos from "./Descuentos"
import { formatPrecio } from "../helpers/formatPrecio"

const CardSlider = ({ columns, productos, onClose }) => {
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: Math.min(columns, productos.length), // Asegurar que no haya más columnas que productos
  //   slidesToScroll: 1,
  //   prevArrow: <CustomArrow direction="prev" />,
  //   nextArrow: <CustomArrow direction="next" />,
  // }

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  if (!productos || productos.length === 0) {
    return null
  }

  return (
    <Slider {...settings}>
      {productos.map((product, index) => (
        <Card key={index} {...product} onClose={onClose} />
      ))}
    </Slider>
  )
}

export const Card = ({
  imagen,
  precioProducto,
  precioRebajado,
  nomProducto,
  codProducto,
  onClose,
}) => {
  const [siEsFavorito, setSiEsFavorito] = useState(false)
  const [hover, setHover] = useState(false)

  const handleToggleFavorito = () => {
    setSiEsFavorito(!siEsFavorito)
  }

  const handleCardClick = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <div
      className={`md:p-4 2xl:p-6 relative w-full`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleCardClick}
    >
      <Link to={"/producto/" + codProducto}>
        <div className="relative mb-4 ">
          <img
            src={imagen}
            alt={nomProducto}
            className="rounded-md md:shadow-lg max-w-[96%] h-auto" // Ajusta el max-w según tus necesidades
          />
          {hover && (
              <button className="absolute w-[96%] h-12 bottom-0 transition  bg-secundario opacity-80 text-white  py-1  text-sm md:px-6">
                Seleccionar opciones
              </button>
          )}
          {precioRebajado &&
            parseInt(precioRebajado) < parseInt(precioProducto) && (
              <div className="absolute top-0 right-0 bg-red-500 text-white px-2 py-1 rounded-md text-sm">
                <Descuentos
                  precioProducto={parseInt(precioProducto)}
                  precioRebajado={parseInt(precioRebajado)}
                />
              </div>
            )}
        </div>
      </Link>
      <hr className="my-6 border-t" />
      <div className="flex justify-between">
        {precioRebajado ? (
          <div className="flex items-center space-x-2 px-2">
            <p
              className={`font-bold text-gray-700 text-xl
              `}
            >
              {formatPrecio(precioRebajado)}
            </p>
            {precioRebajado < precioProducto && (
              <p
                className={`text-gray-500 line-through mr-2
                 text-md
                `}
              >
                {formatPrecio(precioProducto)}
              </p>
            )}
          </div>
        ) : (
          <p className="text-xl text-gray-700 font-bold ">
            {formatPrecio(precioProducto)}
          </p>
        )}
        <FontAwesomeIcon
          icon={siEsFavorito ? faHeart : heartRegular}
          className={`cursor-pointer ${
            siEsFavorito ? "text-red-500" : " text-blue-500"
          }`}
          onClick={handleToggleFavorito}
        />
      </div>

      <p className={`pl-2 text-base`}>
        {nomProducto.length > 20
          ? nomProducto.slice(0, 30) + "..."
          : nomProducto}
      </p>
    </div>
  )
}

export default CardSlider
