import React, { useEffect, useState, useReducer } from "react"
import { Link } from "react-router-dom"
import { obtenerPedidosPorCliente } from "../../../../utils/apiPedidos"
import { formatPrecio } from "../../../../helpers/formatPrecio"
import { formatearFechaPedido } from "../../../../helpers/formatFechaPedido"

const Pedidos = () => {
  const [userData] = useState(JSON.parse(localStorage.getItem("userData")))
  const [pedidos, setPedidos] = useState([])


  useEffect(() => {
    const fetchPedidos = async () => {
      try {
        const response = await obtenerPedidosPorCliente(userData.codCliente)


        if (response && response.status) {
          const pedidosFormateados = response.data.map((pedido) => ({
            ...pedido,
            fecPedido: new Date(pedido.fecPedido), // Convertir a objeto Date directamente
          }))

          // Ordenar pedidos por fecha, del más nuevo al más antiguo
          pedidosFormateados.sort((a, b) => b.fecPedido - a.fecPedido)

          setPedidos(pedidosFormateados)

        } else {
          console.log("No hay pedidos aún")
        }
      } catch (error) {
        console.error("Error al obtener pedidos:", error)
      }
    }

    fetchPedidos()
  }, [userData.codCliente])

  return (
    <div className="flex flex-col p-6 md:p-20">
      {pedidos && pedidos.length > 0 ? (
        pedidos.map((pedido) => (
          <Link
            to={`/pedido/${pedido.numPedido}`}
            key={pedido.numPedido}
            className="w-full"
          >
            <div className="w-full p-5 border-2 hover:border-secundario rounded-md mb-5">
              <div className="pb-3">
                <h1 className="text-primario font-bold ">
                  {formatearFechaPedido(pedido.fecPedido)}|{" "}
                  {formatPrecio(pedido.total)}
                </h1>
                <h1 className="text-primario  text-sm">
                  Número de Pedido #{pedido.numPedido}
                </h1>
              </div>

              <div className="flex space-x-4">
                {pedido.productos &&
                  pedido.productos
                    .slice(0, 10)
                    .map((producto, index) => (
                      <img
                        key={index}
                        src={producto.imagen}
                        alt="Imagen producto"
                        className="hidden md:flex md:h-24 w-24 border"
                      />
                    ))}
              </div>
            </div>
          </Link>
        ))
      ) : (
        <p>Aún no ha realizado ningún pedido.</p>
      )}
    </div>
  )
}

export default Pedidos
