import { faArrowRotateBack } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { getOrderById } from "../../../../utils/apiPedidos"
import { formatearFechaPedido } from "../../../../helpers/formatFechaPedido"
import { useFetchNomDepto } from "../../../../hooks/useFetchNomDepto"
import { useFetchNomCiud } from "../../../../hooks/useFetchNomCiud"
import { DetallesFacturacion } from "../../../../components/compras/DetallesFacturacion"
import { ResumenPedidos } from "../../../../components/compras/ResumenPedidos"
import { ProductosPedidos } from "../../../../components/compras/ProductosPedidos"
import { EstadoPedido } from "../../../../components/pedidos/EstadoPedido"

const DetallesPedidos = () => {

  const [pedido, setPedido] = useState({})
  const [cantidadTotal, setCantidadTotal] = useState(0)

  const { numPedido } = useParams()

  const {
    nomCliente,
    apeCliente,
    fecPedido,
    codMetpago,
    dirCliente,
    telCliente,
    codDepto,
    subTotal,
    costoEnvio,
    total,
    productos,
    codEstpedido
  } = pedido

  console.log(pedido)

  const nomDepto = useFetchNomDepto(codDepto)
  const nomCiud = useFetchNomCiud(pedido.codCiudad, pedido.codDepto)

  useEffect(() => {
    const fetchOrderById = async (numPedido) => {
      try {
        const response = await getOrderById(numPedido)

        if (response) {
          setPedido(response.data)
        } else {
          console.error(
            "La respuesta de la API no tiene los datos esperados:",
            response
          )
        }
      } catch (error) {
        console.error("Error al obtener el pedido:", error.message)
      }
    }

    if (numPedido) {
      // Evitar llamadas redundantes si numPedido no cambia
      fetchOrderById(numPedido)
    }
  }, [numPedido])

  useEffect(() => {
    if (pedido.productos) {
      const total = pedido.productos.reduce((accumulator, producto) => {
        return accumulator + parseInt(producto.cantidad)
      }, 0)

      setCantidadTotal(total)
    }
  }, [pedido])



  return (
    <div className="flex flex-col md:flex-row p-6 md:p-20">
      <div className="w-full md:w-2/3 flex flex-col space-x-2 md:pr-32">
        <Link to={`/cuenta/pedidos`} className="font-bold pb-8">
          <FontAwesomeIcon icon={faArrowRotateBack} />
          <span> Historial de Pedidos</span>
        </Link>
        <EstadoPedido estado={codEstpedido} />
        {productos && <ProductosPedidos productos={productos} />}
      </div>
      <div className="w-full md:w-1/3">
        <div className="border-b">
          <h2 className="font-bold uppercase text-primario mb-6 text-lg">
            DETALLES DEL PEDIDO
          </h2>
          <table className="w-full mb-8 text-sm">
            <tbody>
              <tr>
                <td>Número de pedido</td>
                <td className="text-right">{numPedido}</td>
              </tr>
              <tr>
                <td>Fecha Pedido</td>
                <td className="text-right">
                  {formatearFechaPedido(fecPedido)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <DetallesFacturacion
          nomCliente={nomCliente}
          apeCliente={apeCliente}
          dirCliente={dirCliente}
          telCliente={telCliente}
          nomCiud={nomCiud}
          nomDepto={nomDepto}
        />
        <div className="border-b mt-4">
          <h2 className="font-bold uppercase text-primario mb-6 text-lg">
            METODO DE PAGO
          </h2>
          <p className="mb-8">{codMetpago}</p>
        </div>
        <ResumenPedidos
          costoEnvio={costoEnvio}
          cantidadTotal={cantidadTotal}
          subTotal={subTotal}
          total={total}
          title="Totales"
        />
      </div>
    </div>
  )
}

export default DetallesPedidos
