

import axios from "axios";

const baseURL = "https://devshopapi.encarga.app";


export const claveTemporal = async (data) => {
    const options = {
      method: "POST",
      url: `${baseURL}/olvidoclave`,
      headers: {
        
      },
      data
    };
  
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const cambiarClave= async (data) => {
    const options = {
      method: "POST",
      url: `${baseURL}/edit/clave/user`,
      headers: {
        // Authorization: getToken(),
      },
      data
    };
  
    try {
      const response = await axios(options);
      return response.data; 
    } catch (error) {
      console.error("Error en la solicitud:", error);
      throw error; 
    }
  };