import React from "react"

const Footer = () => {
  return (
    <div className="bg-gray-100 h-16 flex items-center justify-center  bottom-0 w-full">
      <h2 className="text-xs p-3 text-center">
        Copyright © 2023 Encarga S.A.S Todos los derechos reservados
      </h2>
    </div>
  );
}

export default Footer
