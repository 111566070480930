import React from "react"
import Footer from "../components/Footer"
import { Outlet } from "react-router-dom"
import TopbarRegistro from "../components/TopbarRegistro"

const LayoutRegistro = () => {
  return (
    <div className="flex flex-col h-screen">
      <TopbarRegistro />
      <div className="flex-grow bg-zinc-100">
        <Outlet />
      </div>

    </div>
  )
}

export default LayoutRegistro
