import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { obtenerProductosPorSubcategoria } from "../../utils/apiProductos";
import { Card } from "../../components/CardSlider";



export const ProductsBySubcategory = () => {

    const { codCategory, codSubcategory } = useParams();

  const [productos, setProductos] = useState([]);
  const [nomCategoria, setNomCategoria] = useState("");

    useEffect(() => {
        const fetchData = async () => {
          try {
            const data = await obtenerProductosPorSubcategoria(codCategory, codSubcategory);
            setProductos(data.productos);
            setNomCategoria(
              data.productos.length > 0
                ? data.productos[0].categoriaPrincipal.nomCategoria
                : ""
            );
          } catch (error) {
            console.error("Error en la solicitud:", error);
          }
        };
        fetchData();
      }, [codCategory]);

  return (
    <div className="flex flex-col md:pl-6 w-5/6">
    <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
    {productos && productos.map((producto) => (
         <Card
         key={producto.id}
         imagen={producto.imagen}
         precioProducto={producto.precioProducto}
         precioRebajado={producto.precioRebajado}
         nomProducto={producto.nomProducto}
         codProducto={producto.codProducto}
       />

    ))}

</div>
</div>
  )
}
