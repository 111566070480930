// ShoppingCartIcon.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";

import { useCartContext } from "../contexts/CartContext";


const ShoppingCartIcon = () => {

  const { cartTotalItems } = useCartContext();


  return (
    <Link to="/cart" className="flex mx-3 text-white">
      <FontAwesomeIcon
        icon={faShoppingCart}
        className="h-4 text-white mx-1"
      />
      <span className="text-sm font-extrabold">{cartTotalItems}</span>
    </Link>
  );
};

export default ShoppingCartIcon;
