import React from "react";
import logoPayu from "../../media/logoPayU.png";
import logoEPayco from "../../media/logoEPayco.png";

const Pagos = ({ metodoPago, setMetodoPago }) => {
  const metodosPago = [
    { codMetodo: 1, nombreMetodo: "ePayco", imagen: logoEPayco  },
    // { codMetodo: 2, nombreMetodo: "PayU Pagos Online", imagen: logoPayu },
    // { codMetodo: 3, nombreMetodo: "Pago en casa", imagen: null },
  ];

  // Si metodoPago no tiene ningún valor, establece el valor predeterminado como 3
  React.useEffect(() => {
    if (!metodoPago) {
      setMetodoPago(1);
    }
  }, [metodoPago, setMetodoPago]);

  return (
    <div className="flex flex-col items-start text-gray-500">
      {metodosPago.map((metodo, index) => (
        <div key={index} className="flex items-center">
          <input
            type="checkbox"
            id={`metodo${index}`}
            value={metodo.codMetodo}
            checked={metodoPago === metodo.codMetodo}
            onChange={(e) => setMetodoPago(Number(e.target.value))}
            className="mr-2"
          />
          <label htmlFor={`metodo${index}`}> {metodo.nombreMetodo}</label>
          {metodo.imagen && (
            <img
              src={metodo.imagen}
              alt={`Logo ${metodo.nombreMetodo}`}
              className="ml-4 h-8"
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default Pagos;
