
import React, { createContext, useContext, useState } from "react";

const RegisterContext = createContext();

export const useRegisterContext = () => {
  return useContext(RegisterContext);
};

export const RegisterProvider = ({ children }) => {
  const [registerData, setRegisterData] = useState({
    emaUsuario: "",
    celUsuario: null,
    codUsuario: "",
    celUsuarioDespuesSms:null
    
  });

  const updateRegisterData = (dataToUpdate) => {
    setRegisterData((prevData) => ({ ...prevData, ...dataToUpdate }));
  };

  return (
    <RegisterContext.Provider
      value={{
        registerData,
        updateRegisterData,
      }}
    >
      {children}
    </RegisterContext.Provider>
  );
};
