import axios from "axios";

const baseURL = "https://devshopapi.encarga.app"
// const getToken = () => {
//   return `Bearer ${localStorage.getItem('token')}`
  
// };

export const getDptos = async (isVendedor) => {
  const options = {
    method: "GET",
    url: `${baseURL}/departamentos?isVendedor=${isVendedor}`,
    headers: {
      //  Authorization: getToken(),
    },
  };

  try {
    const response = await axios(options);
    return response.data.datos; 
  } catch (error) {
    console.error("Error en la solicitud:", error);
    throw error; 
  }
};


export const getCitys= async (codDepto, isVendedor) => {
  const options = {
    method: "GET",
    url: `${baseURL}/ciudades/${codDepto}`,
    headers: {
      //  Authorization: getToken(),
    },
  };

  try {
    const response = await axios(options);
    return response.data.datos;
  } catch (error) {
    console.error("Error en la solicitud:", error);
    throw error; 
  }
};