import React from "react"
import { Link, useNavigate } from "react-router-dom"

const SeleccionRegistro = () => {
  return (
    <div className="flex flex-col items-center pt-20">
      <div className="bg-white rounded-lg shadow-lg p-14 max-w-md overflow-hidden">
        <div className="mb-4 text-center">
          <h1 className="text-2xl font-medium text-primario pb-2">
            Para crear tu cuenta te pediremos algunos datos
          </h1>
          <p>Solo te tomará unos minutos.</p>
        </div>
        <div className="flex flex-col items-center">
          <Link to="/registro-usuario" className="w-3/4">
            <button className="bg-secundario p-3 w-full text-white mt-4 rounded-lg text-base font-medium">
              Crear cuenta personal
            </button>
          </Link>
          <Link to="/registro-vendedor" className="w-3/4">
            <button className="bg-secundario/30 p-3 w-full text-secundario mt-4 rounded-lg text-base font-medium">
              Crear cuenta de vendedor
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default SeleccionRegistro
