// CustomArrow.js
import React from "react";

const CustomArrow = ({ direction, onClick }) => (
  <div
    onClick={onClick}
    className={`absolute top-1/2 cursor-pointer rounded-full py-2 px-4 bg-gray-100 shadow-xl hover:bg-gray-300 opacity-40 ${
      direction === "next" ? "right-2" : "z-20"
    }`}
  >
    <span>{direction === "next" ? "❯" : "❮"}</span>
  </div>
);

export default CustomArrow;
