import React from "react"

import american from "../../media/metodosPago/icon-american-express.svg"
import mastercard from "../../media/metodosPago/icon-mastercard.svg"
import visa from "../../media/metodosPago/icon-visa.svg"
import pse from "../../media/metodosPago/icon-pse.svg"
import efecty from "../../media/metodosPago/icon-efecty.svg"
import maestro from "../../media/metodosPago/icon-maestro.svg"
import matrix from "../../media/metodosPago/icon-matrix.svg"

export const MetodosPago = () => {
  return (
    <div className="flex flex-row space-x-2 pt-5 w-full">
      <img src={american} alt="" className="h-8 w-8" />
      <img src={mastercard} alt="" className="h-8 w-12" />
      <img src={visa} alt="" className="h-8 w-12" />
      <img src={maestro} alt="" className="h-8 w-12" />
      <img src={pse} alt="" className="h-8 w-8" />
      <img src={efecty} alt="" className="h-8 w-8" />
      <img src={matrix} alt="" className="h-8 w-12" />
    </div>
  )
}
