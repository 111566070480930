import React from "react"
import { formatPrecio } from "../../helpers/formatPrecio"

export const ProductosPedidos = ({ productos }) => {
  
  return (
    <div className="pt-12 ">
      {productos.map((producto) => (
        <div key={producto.codProducto} className="flex flex-col md:flex-row pb-6">
          <div className="w-3/5 md:w-1/5">
            <img
              src={producto.imagen}
              alt={producto.nombre}
              className="h-auto"
            />
          </div>
          <div className="flex-1 p-4">
            <p className="font-semibold">{producto.nomProducto}</p>
            <div className="flex space-x-2 text-gray-500">
              <p>Referencia Producto: </p>
              <p>{producto.refProducto}</p>
            </div>
            {producto.terminos &&
              producto.terminos.map((termino) => (
                <div key={termino.codTermino} className="flex space-x-2 text-gray-500">
                  <p>{termino.nomAtributo}: </p>
                  <p>{termino.nomTermino}</p>
                </div>
              ))}
            {producto.precioRebajado ? (
              <div className="flex items-center space-x-2">
                   <p className="font-bold text-gray-700">
                  {formatPrecio(producto.precioRebajado)}
                </p>
                {producto.precioRebajado < producto.precioProducto && (
                  <p className="text-gray-500 line-through mr-2">
                    {formatPrecio(producto.precioProducto)}
                  </p>
                )}
             
              </div>
            ) : (
              <p className="font-bold text-gray-700">
                {formatPrecio(producto.precioProducto)}
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
