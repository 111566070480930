import React, { createContext, useContext, useState, useEffect } from "react"
import { useUserContext } from "./UserContext"
export const CartContext = createContext()

export const useCartContext = () => {
  return useContext(CartContext)
}

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([])
  const [cartTotal, setCartTotal] = useState(0)
  const [cartTotalItems, setCartTotalItems] = useState(0)
  const [cartTotalSinDescuento, setCartTotalSinDescuento] = useState(0)
  const [cartTotalConDescuento, setCartTotalConDescuento] = useState(0)

  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userData"))
  )

  const { isLoggedIn } = useUserContext()

  const addItem = (item) => {
    const itemIndex = cart.findIndex((itemInCart) => {
      const mismosAtributos =
        itemInCart.atributos &&
        item.atributos &&
        itemInCart.atributos.every((atributoInCart) =>
          item.atributos.some(
            (atributo) =>
              atributoInCart.codAtributo === atributo.codAtributo &&
              atributoInCart.codTermino === atributo.codTermino
          )
        )

      return itemInCart.codProducto === item.codProducto && mismosAtributos
    })

    if (itemIndex === -1) {
      setCart((prevCart) => [...prevCart, { ...item, cantidad: item.cantidad }])
    } else {
      const newCart = [...cart]
      newCart[itemIndex].cantidad += item.cantidad
      setCart(newCart)
    }
  }

  const removeItem = (itemToRemove) => {
    const newCart = cart.filter((itemInCart) => {
      const mismosAtributos =
        itemInCart.atributos &&
        itemToRemove.atributos &&
        itemInCart.atributos.every((atributoInCart) =>
          itemToRemove.atributos.some(
            (atributo) =>
              atributoInCart.codAtributo === atributo.codAtributo &&
              atributoInCart.codTermino === atributo.codTermino
          )
        )

      return !(
        itemInCart.codProducto === itemToRemove.codProducto && mismosAtributos
      )
    })

    setCart(newCart)
  }

  const clear = () => {
    setCart([])
  }

  const isInCart = (itemId) => {
    return cart.some((item) => item.id === itemId)
  }

  const getCartTotalSinDescuento = () => {
    return cart.reduce(
      (acc, item) => acc + item.precioProducto * item.cantidad,
      0
    )
  }

  const getCartTotalConDescuento = () => {
    return cart.reduce((acc, item) => {
      const precioUnitario = item.precioRebajado || item.precioProducto
      return acc + precioUnitario * item.cantidad
    }, 0)
  }

  // const getEnvio = () => {
  //   return 8500
  // }

  // const getCartTotal = () => {
  //   return getCartTotalConDescuento() + getEnvio()
  // }

  const getTotalItems = () => {
    return cart.reduce((acc, item) => acc + item.cantidad, 0)
  }

  const updateItemQuantity = (itemId, newQuantity) => {
    const updatedCart = cart.map((item) => {
      if (item.codProducto === itemId) {
        return { ...item, cantidad: newQuantity }
      }
      return item
    })
    setCart(updatedCart)
  }

  useEffect(() => {
    setCartTotalSinDescuento(getCartTotalSinDescuento())
  }, [cart])

  useEffect(() => {
    setCartTotalConDescuento(getCartTotalConDescuento())
    setCartTotalItems(getTotalItems())
  }, [cart])

  useEffect(() => {
    const localStorageKey = isLoggedIn
      ? "cartData"
      : `cartDataNonLoggedIn_${userData?.codUsuario || "unknown"}`

    const storedCartData = localStorage.getItem(localStorageKey)
    const expirationKey = `${localStorageKey}_expirationTime`
    const storedExpirationTime = localStorage.getItem(expirationKey)

    if (!isLoggedIn) {
      if (storedCartData && storedExpirationTime) {
        const currentTime = new Date().getTime()
        if (currentTime < parseInt(storedExpirationTime, 10)) {
          setCart(JSON.parse(storedCartData))
        } else {
          // Si ha expirado, limpia el carrito y el localStorage
          clear()
          localStorage.removeItem(localStorageKey)
          localStorage.removeItem(expirationKey)
        }
      } else {
        setCart(JSON.parse(storedCartData))
      }
    } else {
      if (storedCartData) {
        const nonLoggedInCart = JSON.parse(storedCartData)
        setCart((prevCart) => [...prevCart, ...nonLoggedInCart])
        localStorage.removeItem(localStorageKey)
        localStorage.removeItem(expirationKey)
      }
    }
  }, [isLoggedIn, userData])

  useEffect(() => {
    const localStorageKey = isLoggedIn
      ? "cartData"
      : `cartDataNonLoggedIn_${userData?.codUsuario || "unknown"}`

    if (!isLoggedIn) {
      // Si el usuario no está logeado, actualiza el carrito no logeado en localStorage
      localStorage.setItem(localStorageKey, JSON.stringify(cart))

      // Establecer la expiración en 42 horas si el carrito no logeado está vacío
      if (cart.length === 0) {
        const expirationTime = new Date().getTime() + 42 * 60 * 60 * 1000 // 42 horas
        localStorage.setItem(
          `${localStorageKey}_expirationTime`,
          expirationTime.toString()
        )
      }
    } else {
      // Si el usuario inicia sesión, actualiza el carrito en localStorage y establece la expiración
      localStorage.setItem("cartData", JSON.stringify(cart))
      const expirationTime = new Date().getTime() + 42 * 60 * 60 * 1000 // 42 horas
      localStorage.setItem("cartExpirationTime", expirationTime.toString())
    }
  }, [cart, isLoggedIn, userData])

  useEffect(() => {
    if (!isLoggedIn) {
      // Si el usuario cierra sesión, vacía el carrito localmente
      clear()
    }
  }, [isLoggedIn])

  return (
    <CartContext.Provider
      value={{
        cart,
        addItem,
        removeItem,
        clear,
        isInCart,
        updateItemQuantity,
        cartTotalItems,
        cartTotalSinDescuento,
        cartTotalConDescuento,
        
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
