import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';


const Navbar = () => {
  return (
    <div className='flex bg-primario  items-center justify-between h-10' >
        <div className='flex text-white text-sm items-center'>
        <FontAwesomeIcon icon={faBars}  className="hidden md:flex mx-3 h-4 text-white"/>
            <span className='mx-3'>Todo</span>
            <span className='hidden md:flex mx-3'>Ofertas del Día</span>
            <span className='hidden md:flex mx-3'>Tarjetas de Regalo</span>
            <span className='hidden md:flex mx-3'>Historial</span>
            <span className='hidden md:flex mx-3'>Servicio al Cliente</span>
            <span className='hidden md:flex mx-3'>Listas</span>
            <Link to="/login">
            <span className='hidden md:flex mx-3'>Vender</span>
            </Link>
           
        </div>
        <div className='hidden md:flex text-white text-sm mx-3'>
            <span>Compra ya las grandes ofertas</span>
        </div>
    </div>
  )
}

export default Navbar