import { useEffect, useState } from "react";
import { getCitys } from "../utils/apiCiudades";

export const useFetchCitys = (codDepto) => {
  const [listaCiudades, setListaCiudades] = useState([]);

  useEffect(() => {
    const getCitysByDepart = async () => {
      try {
        const citys = codDepto ? await getCitys(codDepto) : [];
        setListaCiudades(citys);
      } catch (error) {
        console.error("Error al obtener los municipios:", error);
      }
    };

    getCitysByDepart();
  }, [codDepto]);


  return listaCiudades;
};



