import { useState, useEffect } from "react"
import { getDptos } from "../utils/apiCiudades"

export const useFetchNomDepto = (codDepto) => {
  const [nomDepto, setNomDepto] = useState('')

  useEffect(() => {
    const fetchDepartamento = async () => {
      try {
        if (!codDepto) return // Si no hay código de departamento, no hagas nada
        const departamentos = await getDptos()
        const departamento = departamentos.find(
          (d) => d.codDepto == codDepto
        )
        if (departamento) {
          setNomDepto(departamento.nomDepto)
        } else {
          console.error("Departamento no encontrado")
        }
      } catch (error) {
        console.error("Error al obtener los departamentos:", error)
      }
    }

    fetchDepartamento()
  }, [codDepto])


  return nomDepto
}
