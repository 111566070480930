import { useState, useEffect } from "react"
import { getCitys } from "../utils/apiCiudades"

export const useFetchNomCiud = (codCiudad, codDepto) => {
    const [nomCiudad, setNomCiud] = useState('')


  useEffect(() => {
    const fetchCiudades = async () => {
      try {
        if (!codCiudad || !codDepto) return // Si no hay código de ciudad o de departamento, no hagas nada
        const municipios = await getCitys(codDepto)
        const getMunicipio = municipios.find(
          (m) => m.codCiudad == codCiudad
        )
        if (getMunicipio) {
          setNomCiud(getMunicipio.nomCiudad)
        } else {
          console.error("Ciudad no encontrada")
        }
      } catch (error) {
        console.error("Error al obtener los municipios:", error)
      }
    }

    if (codCiudad && codDepto) {
      fetchCiudades();
    }
  }, [codCiudad, codDepto])



  return nomCiudad
}
