import React from "react";
import { Link } from "react-router-dom";



export const CategoryCard = ({categoria, imagen}) => {





  return (
    <Link to={`/category/${categoria.codCategoria}`} className="relative overflow-hidden hover:rounded-2xl rounded-2xl">
      <div className="max-w-md rounded-2xl bg-neutral-100 border border-neutral-100 shadow-lg mb-6  ">
        <div className="transition-transform duration-300 transform-gpu hover:scale-110 ">
          <div className="h-56 md:h-72 ">
            <img src={imagen} alt={categoria.nomCategoria} className="object-cover w-full h-full rounded-t-2xl" />
          </div>
        </div>
        <div className="p-5">
          <div className="text-xs font-semibold text-grisClaro">{categoria.nomPadre}</div>
          <div>
            <span className="text-xl font-semibold text-azulTitulos font-fredoka">{categoria.nomCategoria}</span>
            <span></span>
          </div>
        </div>
      </div>
    </Link>
  );
};
