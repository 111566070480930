import React from 'react'

export const DetallesFacturacion = ({nomCliente, apeCliente, dirCliente, telCliente, nomCiud, nomDepto}) => {
  return (
    <div className="border-b mt-4">
    <h2 className="font-bold uppercase text-primario mb-6 text-lg">
      ENTREGA{" "}
    </h2>
    <table className="w-full mb-8 text-sm">
      <tbody>
        <tr>
          <td className="font-bold pb-3">Dirección de entrega</td>
        </tr>
        <tr>
          <td>
            {nomCliente} {apeCliente}
          </td>
        </tr>
        <tr>
          <td>{dirCliente}</td>
        </tr>
        <tr>
          <td className="capitalize ">
            {nomCiud} - {nomDepto}
          </td>
        </tr>
        <tr>
          <td>{telCliente}</td>
        </tr>
      </tbody>
    </table>
  </div>
  )
}
