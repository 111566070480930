import React, { useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"
import { claveTemporal } from "../../utils/apiPerfil"
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons"

const OlvidoContraseña = () => {
  const [email, setEmail] = useState("")
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [mostrarEnvioClave, setMostrarEnvioClave] = useState(false) // Nuevo estado

  const navigate = useNavigate()

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    setIsValidEmail(emailRegex.test(email))
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
    setIsValidEmail(true)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    validateEmail()

    if (isValidEmail) {
      setIsLoading(true)

      try {
        const response = await claveTemporal({ email })
        setTimeout(() => {
          setMostrarEnvioClave(true)
        }, 1500)
      } catch (error) {
        console.log("Error durante el inicio de sesión:", error)

        if (error.response && error.response.status === 400) {
          toast.error("El correo electrónico no se encuentra registrado")
        }
      }

      setIsLoading(false)
    } else {
      console.log("Correo electrónico no válido")
    }
  }

  return (
    <div className="flex flex-col items-center pt-20">
       {mostrarEnvioClave ? (
        <EnvioClave /> 
      ) : (
      <div className="bg-white rounded-lg shadow-lg p-14 max-w-md overflow-hidden">
        <div className="mb-4">
          <h1 className="text-2xl font-medium text-primario">
            ¿Ha olvidado la contraseña?
          </h1>
          <p className="pt-2 text-sm">
            Escriba su dirección de correo electrónico registrada, para cambiar
            la contraseña de su cuenta de EncargaShop.
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col pt-2">
            <label htmlFor="email" className="text-sm text-gray-500 mb-1 pl-2">
              Digite su email
            </label>
            <input
              type="email"
              className={`border focus:outline-secundario p-3 rounded-lg ${
                !isValidEmail && "border-red-500"
              }`}
              value={email}
              onChange={handleEmailChange}
            />
            {!isValidEmail && (
              <p className="text-red-500 text-sm mt-1">
                Por favor, ingrese un correo electrónico válido.
              </p>
            )}
          </div>
          <button
            type="submit"
            className={`${
              !isValidEmail || isLoading ? "bg-gray-300" : "bg-secundario"
            } p-3 w-full text-white mt-4 rounded-lg text-base font-medium`}
            disabled={!isValidEmail || isLoading}
          >
            {isLoading ? (
              <svg
                className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.86 3.182 7.978l2.02-2.687zm8.838 1.57A9.503 9.503 0 0017 12h-4a5.002 5.002 0 00-4.646-4.995L8 7.005A7.964 7.964 0 0112 4c4.418 0 8 3.582 8 8h-4a3.001 3.001 0 01-3.162 2.862l1.02-2.703z"
                ></path>
              </svg>
            ) : (
              "Continuar"
            )}
          </button>
        </form>
      </div> )}
      <ToastContainer position="top-center" style={{ width: "500px" }} />
    </div>
  )
}

export const EnvioClave = () => {
  return (
    <div className="flex flex-col pt-20 items-center bg-zinc-100 ">
      <div className="bg-white rounded-lg shadow-lg p-14 max-w-lg overflow-hidden">
        <div className="mb-4">
          <h1 className="text-2xl font-medium text-primario">
            Envio de Nueva Contraseña
          </h1>
          <p className="text-sm pt-4">
            Se ha enviado al correo electrónico una nueva clave de ingreso.
          </p>
          <div className="flex justify-center">
            <FontAwesomeIcon
              icon={faCircleCheck}
              className="text-secundario h-16 p-4 mt-4"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OlvidoContraseña
