import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"

import { useCartContext } from "../../contexts/CartContext"
import FormCheckout from "./FormCheckout"
import { formatPrecio } from "../../helpers/formatPrecio"
import useCalculoEnvio from "../../hooks/useCalculoEnvio"

const Checkout = () => {
  const { cart, cartTotalItems, cartTotalSinDescuento, cartTotalConDescuento } =
    useCartContext()

  const [userData] = useState(JSON.parse(localStorage.getItem("userData")))
  const [codCiudad, setCodCiudad] = useState(userData?.ciuUsuario ?? "")

  const { costoEnvio } = useCalculoEnvio(cart, codCiudad)

  const getCartTotal = () => {
    return cartTotalConDescuento + costoEnvio
  }

  const totalAPagar = getCartTotal()


  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full pl-4 md:w-2/3 md:pl-10 md:ml-16 mt-20">
        <h2 className="font-bold uppercase text-primario mb-6 text-lg">
          Datos de Facturación y envío
        </h2>
        <FormCheckout
          onCityChange={setCodCiudad}
          costoEnvio={costoEnvio}
          totalAPagar={totalAPagar}
        />
      </div>
      <div className="w-full pl-4 md:w-1/3 pr-10 mr-16 mt-20">
        <div className="">
          <h2 className="font-bold uppercase text-primario mb-6 text-lg">
            Tu pedido
          </h2>
          <div className="border-b">
            <table className="w-full mb-4 ">
              <tbody>
                <tr>
                  <td>{cartTotalItems} Productos</td>
                </tr>
                <tr>
                  <td>Precio Original</td>
                  <td className="text-right">
                    {formatPrecio(cartTotalSinDescuento)}
                  </td>
                </tr>
                <tr>
                  <td>Descuento</td>
                  <td className="text-right">
                    {formatPrecio(
                      cartTotalSinDescuento - cartTotalConDescuento
                    )}
                  </td>
                </tr>
                <tr className="font-bold mt-6">
                  <td className="">Subtotal</td>
                  <td className="text-right">
                    {formatPrecio(cartTotalConDescuento)}
                  </td>
                </tr>
                <tr className="font-bold mt-6">
                  <td className="w-1/3">Envío</td>
                  <td className="text-right">
                    {codCiudad ? (
                      <div className="">{formatPrecio(costoEnvio)}</div>
                    ) : (
                      <p className="text-xs text-gray-500">
                        Introduce tu ciudad para calcular el costo del envio.
                      </p>
                    )}
                  </td>
                </tr>

                <tr className="font-bold mt-6">
                  <td className="">Total</td>
                  <td className="text-right">{formatPrecio(totalAPagar)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mb-6 mt-4 ">
            <table className="border-collapse w-full">
              <tbody className="">
                {cart.map((item, index) => (
                  <tr key={index} className="border-b">
                    <td className="w-28">
                      <img className="w-28" src={item.imagen} alt="" />
                    </td>
                    <td className="pt-3 pl-6">
                      <h2 className="underline mb-2 w-full md:w-auto">
                        <Link to={"/producto/" + item.codProducto}>
                          {item.nomProducto}
                        </Link>
                      </h2>
                      <div className="pb-2">
                        <span className="text-sm">
                          {formatPrecio(
                            item.precioRebajado || item.precioProducto
                          )}
                        </span>
                        <br />
                        <span className="text-xs text-gray-500">
                          {item.nomAatributo}
                          {item.atributos &&
                            item.atributos.map((atributo) => (
                              <span key={atributo.codAtributo}>
                                - {atributo.nomTermino}
                              </span>
                            ))}{" "}
                          / Cantidad: {item.cantidad}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Checkout
