import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import { useCartContext } from "../../contexts/CartContext"
import { formatPrecio } from "../../helpers/formatPrecio"
import CardSliderProdRelaci from "../../components/CardSliderProdRelaci"

const ModalCart = ({
  isOpen,
  onClose,
  producto,
  atributosSeleccionados,
  cantidad,
  productosRelacionados,
}) => {
  const {
    cart,
    cartTotal,
    removeItem,
    cartTotalItems,
    updateItemQuantity,
    cartTotalSinDescuento,
    cartTotalConDescuento,
  } = useCartContext()



  useEffect(() => {
    // Función para deshabilitar el scroll en el cuerpo de la página
    const disableBodyScroll = () => {
      document.body.style.overflow = "hidden"
    }

    // Función para habilitar el scroll en el cuerpo de la página
    const enableBodyScroll = () => {
      document.body.style.overflow = "auto"
    }

    // Habilitar/deshabilitar el scroll al abrir/cerrar el modal
    if (isOpen) {
      disableBodyScroll()
    } else {
      enableBodyScroll()
    }

    // Limpiar el efecto al desmontar el componente
    return () => enableBodyScroll()
  }, [isOpen])

  useEffect(() => {
    const handleBodyClick = (e) => {
      if (!e.target.closest(".modal-container") && isOpen) {
        onClose()
      }
    }

    if (isOpen) {
      document.body.addEventListener("mousedown", handleBodyClick)
    }

    return () => {
      document.body.removeEventListener("mousedown", handleBodyClick)
    }
  }, [isOpen, onClose])

  return (
    <div>
      {isOpen && (
        <div className="">
          <div className="fixed inset-0 bg-black opacity-50 z-40 overlay"></div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="modal-container relative mx-auto w-4/5 md:w-1/2 ">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="justify-between py-4 px-8 border-b border-solid border-slate-200 rounded-t items-center">
                  <button
                    className="absolute top-0 right-0 m-4 text-gray-500 cursor-pointer"
                    onClick={onClose}
                  >
                    X
                  </button>
                  <h1 className="uppercase md:text-2xl font-bold md:py-6 ">
                    Producto Añadido Al Carrito
                  </h1>
                  <div className="flex flex-col md:flex-row">
                    <div className="w-full md:w-1/2 flex flex-col md:flex-row md:pt-8 md:border-r-2">
                      <img src={producto.imagen} className="h-24 w-24 md:h-32 md:w-28 pr-2" alt="" />
                      <div className="flex flex-col md:pr-4">
                        <h3 className="font-semibold">
                          {producto.nomProducto}
                        </h3>
                        <div className="flex items-center space-x-2 my-1">
                          {producto.precioRebajado &&
                            producto.precioRebajado <
                              producto.precioProducto && (
                              <p className="text-gray-500 line-through">
                                {formatPrecio(producto.precioProducto)}
                              </p>
                            )}
                          <p className=" font-bold text-gray-700">
                            {formatPrecio(
                              producto.precioRebajado || producto.precioProducto
                            )}
                          </p>
                        </div>
                        {producto.atributos &&
                          producto.atributos.map((atributo) => (
                            <div
                              key={atributo.codAtributo}
                              className="mb-0.5 flex text-sm"
                            >
                              <p className="">{atributo.nomAtributo}: &nbsp;</p>
                              <p>
                                {
                                  atributosSeleccionados[atributo.codAtributo]
                                    .nomTermino
                                }
                              </p>
                            </div>
                          ))}
                        <span className="text-sm">Cantidad : {cantidad} </span>
                      </div>
                    </div>
                    <div className="w-full md:w-1/2 md:pl-8">
                      <h3 className="text-sm">Tu carrito</h3>
                      <table className="w-full">
                        <tbody className="text-xs">
                          <tr>
                            <td>{cartTotalItems} Productos</td>
                          </tr>
                          <tr>
                            <td >Precio Original</td>
                            <td className="text-right">
                              {formatPrecio(cartTotalSinDescuento)}
                            </td>
                          </tr>
                          <tr>
                            <td>Descuento</td>
                            <td className="text-right">
                              {formatPrecio(
                                cartTotalSinDescuento - cartTotalConDescuento
                              )}
                            </td>
                          </tr>

                          <tr className="font-bold mt-6">
                            <td className="">Subtotal</td>
                            <td className="text-right">
                              {formatPrecio(cartTotalConDescuento)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="mt-6 space-y-3">
                        <Link to={"/checkout"}>
                          <button className="bg-secundario hover:bg-white hover:border hover:border-secundario hover:text-secundario text-white font-bold py-3 px-4 rounded w-full">
                            Finalizar compra
                          </button>
                        </Link>

                        <button
                          onClick={()=> onClose()}
                          className="border border-secundario hover:bg-secundario hover:text-white text-secundario font-bold py-3 px-4 rounded w-full"
                        >
                          Seguir comprando
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hidden md:block ">
                <CardSliderProdRelaci columns={4} productos={productosRelacionados} onClose={onClose} />


                </div>

              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ModalCart
