import React, { useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import { cambiarClave } from "../../../utils/apiPerfil"


const DetallesCuenta = () => {
  const [userData] = useState(JSON.parse(localStorage.getItem("userData")))
  const [email] = useState(userData?.emaUsuario ?? "")
  const [clave, setClave] = useState("")
  const [newClave, setNewClave] = useState("")
  const [confirmarClave, setConfirmarClave] = useState("")

  const isValidClave = (password) => {
    if (password.trim() === "") return false;
    const passwordRegex = /^(?=.*[A-Z])(?=.*[\W_])(?=.{8,})/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (newClave !== confirmarClave) {
      toast.error("La nueva contraseña y la confirmación no coinciden");
      return;
    }
  
    if (!isValidClave(newClave)) {
      toast.error(
        "La nueva contraseña debe contener al menos 8 caracteres, una mayúscula y un carácter especial."
      );
      return;
    }
  
    try {
      const formData = new FormData();
      formData.append("email", email);
      formData.append("clave", clave);
      formData.append("nuevaClave", newClave);
  
      const response = await cambiarClave(formData);
  
      console.log("Contraseña cambiada con éxito");
  
      setClave("");
      setNewClave("");
      setConfirmarClave("");
  
      toast.success("Contraseña cambiada con éxito");
    } catch (error) {
      console.error("Error en la solicitud:", error);
      toast.error("Error al cambiar la contraseña");
    }
  };
  


  return (
    <div className=" md:w-2/4 p-8 m-auto md:p-20">
      <div className="mb-4 ">
        <div className="flex space-x-1 items-center ">
          <span className="text-sm text-gray-500 mb-1 ">
            Correo Electrónico :
          </span>
          <span className="font-bold text-sm mb-1 "> {email}</span>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="md:mr-20">
        <div className="mb-4">
          <label
            htmlFor="clave"
            className="text-sm text-gray-500 mb-1 pl-2 mt-6"
          >
            Contraseña Actual
          </label>
          <input
            type="password"
            id="clave"
            className="border rounded-md p-2 mt-1 w-full"
            value={clave}
            onChange={(e) => setClave(e.target.value)}
            required
          />
        
        </div>
        <div className="mb-4">
          <label
            htmlFor="newClave"
            className="text-sm text-gray-500 mb-1 pl-2 mt-6"
          >
            Nueva Contraseña
          </label>
          <input
            type="password"
            id="newClave"
            className={`border rounded-md p-2 mt-1 w-full ${
              newClave.trim() === ""
                ? "border-gray-300"
                : !isValidClave(newClave)
                ? "border-red-500"
                : "border-gray-300"
            }`}
            value={newClave}
            onChange={(e) => setNewClave(e.target.value)}
            required
          />
          <p
            className={`text-sm mt-1 pl-2 ${
              newClave.trim() === ""
                ? "text-gray-500"
                : !isValidClave(newClave)
                ? "text-red-500"
                : "text-green-500"
            }`}
          >
            {newClave.trim() === ""
              ? "La contraseña debe contener al menos 8 caracteres, una mayúscula y un carácter especial."
              : isValidClave(newClave)
              ? "La contraseña cumple con los requisitos."
              : "La contraseña debe contener al menos 8 caracteres, una mayúscula y un carácter especial."}
          </p>
        </div>
        <div className="mb-4">
          <label
            htmlFor="confirmarClave"
            className="text-sm text-gray-500 mb-1 pl-2 mt-6"
          >
            Confirmar nueva contraseña
          </label>
          <input
            type="password"
            id="confirmarClave"
            className={`border rounded-md p-2 mt-1 w-full ${
              confirmarClave.trim() === ""
                ? "border-gray-300"
                : !isValidClave(confirmarClave)
                ? "border-red-500"
                : "border-gray-300"
            }`}
            value={confirmarClave}
            onChange={(e) => setConfirmarClave(e.target.value)}
            required
          />
          <p
            className={`text-sm mt-1 pl-2 ${
              confirmarClave.trim() === ""
                ? "text-gray-500"
                : !isValidClave(confirmarClave)
                ? "text-red-500"
                : "text-green-500"
            }`}
          >
            {/* {clave.trim() === ""
              ? "La contraseña debe contener al menos 8 caracteres, una mayúscula y un carácter especial."
              : isValidClave(confirmarClave)
              ? "La contraseña cumple con los requisitos."
              : "La contraseña debe contener al menos 8 caracteres, una mayúscula y un carácter especial."} */}
          </p>
        </div>
        <ToastContainer />

        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-secundario p-3 px-8 text-white rounded-lg text-sm font-medium mt-4 uppercase"
          >
            {" "}
            Guardar los cambios
          </button>
        </div>
      </form>
    </div>
  )
}

export default DetallesCuenta
