import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Categorias from "./Categorias";
import FiltroGenerico from "./FiltroGenerico";
import { useFetchCategorias } from "../hooks/useFetchCategorias";
import { obtenerVendedores } from "../utils/apiVendedores";

const SideBarProductos = ({ setFilteredProducts }) => {
  const { codCategory } = useParams();
  const [vendedoresSeleccionados, setVendedoresSeleccionados] = useState([]);
  const [categoriasSeleccionadas, setCategoriasSeleccionadas] = useState([]);
  const [vendedores, setVendedores] = useState([])


  const navigate = useNavigate();
  useEffect(() => {
    const fetchVendedores = async () => {
      try {
        const data = await obtenerVendedores(); 
        setVendedores(data.datos);
      } catch (error) {
        console.error("Error al obtener vendedores:", error);
      }
    };

    fetchVendedores();
  }, []);



  const listaCategorias = useFetchCategorias();
  console.log(listaCategorias);

  const categoriasHijas = listaCategorias
    ? listaCategorias.filter((categoria) => categoria.codPadre === codCategory)
    : [];



  

    const handleVendedoresChange = (vendedorSeleccionado) => {
      if (vendedorSeleccionado === vendedoresSeleccionados) {
        // Si se selecciona el mismo vendedor, deseleccionarlo
        setVendedoresSeleccionados(null);
      } else {
        // Seleccionar el nuevo vendedor y navegar a su página
        setVendedoresSeleccionados(vendedorSeleccionado);
        navigate(`/productos/seller/${vendedorSeleccionado.codVendedor}`);
      }
    };



  const handleCategoriaChange = (e) => {
    const { value, checked } = e.target;
    setCategoriasSeleccionadas((prevCategorias) => {
      if (checked) {
        return [...prevCategorias, value];
      } else {
        return prevCategorias.filter((categoria) => categoria !== value);
      }
    });

    // Navegar a la nueva URL con las categorías seleccionadas
    navigate(`/productos/category/51/${value}`);
  };



  return (
    <div className="md:w-1/6">
      <Categorias listaCategorias={categoriasHijas} handleCategoriaChange={handleCategoriaChange} />
      <FiltroGenerico
        listaItems={vendedores}
        titulo="Vendedores"
        handleItemChange={handleVendedoresChange}
        items={vendedoresSeleccionados ? [vendedoresSeleccionados] : []} 
        idProp="codVendedor" 
        nombreProp="razonSocial"
      />
       {vendedoresSeleccionados.length > 0 && (
        <div className="">
          {vendedoresSeleccionados.map((vendedor) => (
            <div className="text-red-300" key={vendedor.codVendedor}>
              <span> {vendedor.razonSocial}</span>
               
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SideBarProductos;

