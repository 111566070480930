import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

import { obtenerProductosPorVendedor } from '../../utils/apiProductos'
import { Card } from '../../components/CardSlider';




export const ProductsBySeller = () => {

    const {codVendedor} = useParams()

    const [productos, setProductos] = useState([]);

useEffect(()=> {
    const fetchData = async () => {
        try{
            const data = await obtenerProductosPorVendedor(codVendedor);
            setProductos(data.productos)
            console.log(data.productos

            )

        } catch(error){
            console.error("Error en la solicitud:", error);
        }
    };
    fetchData()

}, [codVendedor])

    



  return (
    <div className="flex flex-col md:pl-6 w-5/6">
        <div className="grid grid-cols-4 gap-4">
        {productos && productos.map((producto) => (
             <Card
             key={producto.id}
             imagen={producto.imagen}
             precioProducto={producto.precioProducto}
             precioRebajado={producto.precioRebajado}
             nomProducto={producto.nomProducto}
             codProducto={producto.codProducto}
           />

        ))}

</div>
    </div>
  )
}
