import { useEffect, useState } from "react";
import { obtenerCategorias } from "../utils/apiProductos";

export const useFetchCategorias = () => {
  const [listaCategorias, setListaCategorias] = useState([]);

  useEffect(() => {
    const getCategorias = async () => {
      try {
        const categorias = await obtenerCategorias()
        setListaCategorias(categorias);
      } catch (error) {
        console.error("Error al obtener las categorias:", error);
      }
    };

    getCategorias();
  }, []);



  return listaCategorias;

};

