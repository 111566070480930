import React from "react"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import Topbar from "../components/Topbar"
import { Outlet } from "react-router-dom"

const Layout = () => {
  return (
    <>
      <Topbar />
      <Navbar />
      <Outlet />
      <Footer />
    </>
  )
}

export default Layout
