import React, { useEffect, useState, useRef } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useParams, Link, useLocation } from "react-router-dom"
import { obtenerProductosGeneral } from "../../utils/apiProductos"
import CustomArrow from "../../components/CustomArrow"
import Descuentos from "../../components/Descuentos"
import Atributos from "./Atributos"
import TabsProductoSimple from "./TabsProductoSimple"
import CardSlider from "../../components/CardSlider"
import { useCartContext } from "../../contexts/CartContext"
import ModalCart from "../compras/ModalCart"
import { formatPrecio } from "../../helpers/formatPrecio"
import { useFetchProductoById } from "../../hooks/useFetchSimpleProduct"


const ProductoSimple = () => {
  const idProducto = useParams().id
  const sliderRef = useRef(null)
 
  const [cantidad, setCantidad] = useState(1)
  const [atributosSeleccionados, setAtributosSeleccionados] = useState([])
  const [showModal, setShowModal] = useState(false)
  const { addItem, cart } = useCartContext()
  const {producto, productosRelacionados} = useFetchProductoById(idProducto)


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomArrow direction="prev" />,
    nextArrow: <CustomArrow direction="next" />,
  }

  const handleImageClick = (index) => {
    if (sliderRef.current) {
      const newIndex = producto.imagenes.length > 0 ? index + 1 : index
      sliderRef.current.slickGoTo(newIndex)
    }
  }

  const handleDecrement = () => {
    if (cantidad > 1) {
      setCantidad(cantidad - 1)
    }
  }

  const handleIncrement = () => {
    setCantidad(cantidad + 1)
  }



  const handleAtributosChange = (nuevosAtributos) => {
    setAtributosSeleccionados(nuevosAtributos);
  };

  const canAddToCart = () => {
    const atributos = producto.atributos
    if (atributos) {
      return atributos.every((atributo) => {
        const selected = atributosSeleccionados[atributo.codAtributo]
        return selected !== undefined
      })
    }
    return true

  }

  return (
    <div>
      <div className="flex flex-col md:flex-row mt-4 p-6 md:p-8 overflow-x-hidden">
        <div className="md:w-4/5">
          <div className="flex flex-col md:flex-row items-center space-x-4">
            <div className="w-full md:w-1/4 md:max-w-xl flex flex-col">
              {producto.imagenes &&
                producto.imagenes.length > 0 &&
                producto.imagenes.map((img, index) => (
                  <div
                    key={index}
                    className=""
                    onClick={() => {
                      handleImageClick(index)
                    }}
                  >
                    <img
                      src={img.urlImagen}
                      alt={producto.nomProducto}
                      className="border my-1 p-2 object-cover cursor-pointer"
                    />
                  </div>
                ))}
            </div>
            <div className="relative w-full md:w-3/4 md:max-w-xl md:pl-10">
              {producto.precioRebajado &&
               parseInt(producto.precioRebajado) < parseInt(producto.precioProducto) && (
                  <div className="absolute top-0 right-0 bg-red-500 text-white px-6 py-2 text-sm font-bold w-fit z-10">
                    <Descuentos
                      precioProducto={parseInt(producto.precioProducto)}
                      precioRebajado={parseInt(producto.precioRebajado)}
                    />
                  </div>
                )}
              {producto.imagenes && (
                <Slider ref={sliderRef} {...settings}>
                  {[
                    producto.imagen,
                    ...producto.imagenes.map((img) => img.urlImagen),
                  ].map((url, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-center"
                    >
                      <img
                        src={url}
                        alt={producto.nomProducto}
                        className="p-2 "
                      />
                    </div>
                  ))}
                </Slider>
              )}
            </div>
          </div>
        </div>
        <div className="md:w-2/5 mt-10  lg:pr-4 lg:pl-14 2xl:pr-36">
          <h1 className="text-3xl font-semibold text-primario capitalize">
            {producto.nomProducto}
          </h1>
          <p className="text-xs text-gray-400  mb-4">{producto.refProducto}</p>
          {/* {producto.precioRebajado &&
          producto.precioRebajado < producto.precioProducto && (
            <div className="top-0 left-0 bg-red-500 text-white px-2 py-1 rounded-md text-sm font-bold w-fit">
              <Descuentos
                precioProducto={producto.precioProducto}
                precioRebajado={producto.precioRebajado}
              />
            </div>
          )} */}

          <div className="flex items-center space-x-2 my-2">
            <p className="text-3xl font-bold text-gray-700">
              {formatPrecio(producto.precioRebajado || producto.precioProducto)}
            </p>
            {producto.precioRebajado &&
              producto.precioRebajado < producto.precioProducto && (
                <p className="text-gray-500 line-through">
                  {formatPrecio(producto.precioProducto)}
                </p>
              )}
          </div>

          {producto.precioRebajado &&
            producto.precioRebajado < producto.precioProducto && (
              <div className="text-green-500 text-xl">
                Ahorro:{" "}
                {formatPrecio(
                  producto.precioProducto - producto.precioRebajado
                )}
              </div>
            )}
          <Atributos
            producto={producto}
            onAtributosChange={handleAtributosChange}
            />
          <div className="flex space-x-2 mt-6 ">
            <CantidadInput
              cantidad={cantidad}
              onDecrement={handleDecrement}
              onIncrement={handleIncrement}
            />
          </div>
          <div className="pt-4">
            <button
              onClick={() => {
                if (canAddToCart()) {
                  const item = {
                    codProducto: producto.codProducto,
                    nomProducto: producto.nomProducto,
                    precioProducto: producto.precioProducto,
                    precioRebajado: producto.precioRebajado,
                    cantidad,
                    atributos: Object.values(atributosSeleccionados),
                    imagen: producto.imagen,
                    codVendedor: producto.codVendedor,
                    peso: producto.peso,
                    altProducto: producto.altProducto,
                    anchoProducto: producto.anchoProducto,
                    lonProducto: producto.lonProducto,
                  }

                  addItem(item)
                  console.log(item)
                  setShowModal(true)
                }
              }}
              className={`bg-secundario rounded py-2 px-4 text-white w-full ${
                canAddToCart() ? "" : "cursor-not-allowed opacity-50"
              }`}
              disabled={!canAddToCart()}
            >
              Agregar al carro
            </button>
            <Link to={"/checkout"}>
              <button className="bg-white border border-secundario rounded py-2 px-4 mt-2 text-secundario  w-full">
                Comprar Ahora
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="mx-8 md:mx-14 mb-6 border-t-2">
        <TabsProductoSimple producto={producto} />
      </div>
      {productosRelacionados.length > 0 && (
        <div className="border-t-2 mx-14">
          <h1 className="text-primario mt-10 font-bold text-xl">
            Productos que te pueden interesar
          </h1>
          <div className="mt-1 mb-6 mx-10">
            <CardSlider columns={4} productos={productosRelacionados} />
          </div>
        </div>
      )}

      {cart && (
        <ModalCart
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          producto={producto}
          atributosSeleccionados={atributosSeleccionados}
          cantidad={cantidad}
          productosRelacionados={productosRelacionados}
        />
      )}
    </div>
  )
}

export const CantidadInput = ({ cantidad, onDecrement, onIncrement }) => (
  <div className="flex items-center">
    <button onClick={onDecrement} className="border rounded-l py-2 px-4">
      -
    </button>
    <input
      type="number"
      value={cantidad}
      readOnly
      className="text-center w-1/3 border-t border-b py-2 h-full pl-4"
    />
    <button onClick={onIncrement} className="border rounded-r py-2 px-4">
      +
    </button>
  </div>
)

export default ProductoSimple
