import React from "react";
import { Link } from "react-router-dom";
import { useFetchCategorias } from "../../hooks/useFetchCategorias";

export const Productos = () => {
  const listaCategorias = useFetchCategorias();


  const categoriasPadre = listaCategorias ? listaCategorias.filter(categoria => categoria.codPadre === "0") : [];


  return (
    <div>
      <nav>
        {categoriasPadre.map(categoria => (
          <ul key={categoria.codCategoria}>
            <li>
              <Link to={`/productos/category/${categoria.codCategoria}`}>
                {categoria.nomCategoria}
              </Link>
            </li>
          </ul>
        ))}
      </nav>
    </div>
  );
};
