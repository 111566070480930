import { useEffect, useState } from "react";
import { getDptos } from "../utils/apiCiudades";

export const useFetchDptos = () => {
  const [listaDepartamentos, setListaDepartamentos] = useState([]);

  useEffect(() => {
    const fecthDptos = async () => {
      try {
        const dptos =  await getDptos();
        setListaDepartamentos(dptos);
      } catch (error) {
        console.error("Error al obtener los departamentos:", error);
      }
    };

    fecthDptos();
  }, []);

  return listaDepartamentos;


};



