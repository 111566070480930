import axios from "axios";

const baseURL = "https://devshopapi.encarga.app"
// const getToken = () => {
//   return `Bearer ${localStorage.getItem('token')}`
  
// };

export const registroEmailUsuario = async (data) => {
  
    const options = {
      method: "POST",
      url: `${baseURL}/registro/usuario/email`,
      headers: {
        //  Authorization: getToken(),
      },
      data
    };
  
    try {
      const response = await axios.request(options);
      return response.data; // Retornar la respuesta del servidor
    } catch (error) {
      if (error.response && error.response.data) {
        return error.response.data; // Manejar el error y obtener la respuesta del servidor
      } else {
        throw error;
      }
    }
  };
  
  

  export const registroTelefonoUsuario = async (data, successCallback, errorCallback) => {
    const options = {
      method: "POST",
      url: `${baseURL}/registro/usuario/enviarsms`,
      headers: {
        // Authorization: getToken(),
      },
      data,
    };
  
    try {
      const response = await axios.request(options);
      successCallback(response);
    } catch (error) {
      errorCallback(error);
    }
  };
  

  export const validarSmsUsuario = async (pin, codUsuario, telefono) => {
    const data = {
      pin: pin, 
      codUsuario: codUsuario,
      telefono: telefono
    };
  
    const options = {
      method: "POST",
      url: `${baseURL}/registro/usuario/validarsms`,
      headers: {
        //  Authorization: getToken(),
  
      },
      data
    };
    const response = await axios.request(options);
    return response.data; 
  };




  export const registroUsuario = async (data) => {
    const options = {
      method: "POST",
      url: `${baseURL}/registro/usuario`,
      headers: {
        // Agrega cualquier encabezado necesario, como Authorization
      },
      data
    };
  
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      throw error;
    }
  };


  export const edicionUsuario = async (data) => {
    const options = {
      method: "POST",
      url: `${baseURL}/editar/usuario`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data
    };
  
    try {
      const response = await axios.request(options);
      return response.data;
    } catch (error) {
      throw error;
    }
  };



export const obtenerTiposDocumento = async () => {
  const options = {
    method: "GET",
    url: `${baseURL}/tiposDocumento`,
    headers: {
      //  Authorization: getToken(),
    },
  };

  try {
    const response = await axios(options);
    return response.data.datos; 
  } catch (error) {
    console.error("Error en la solicitud:", error);
    throw error; 
  }
};

export const obtenerTiposPersona = async () => {
  const options = {
    method: "GET",
    url: `${baseURL}/tiposPersona`,
    headers: {
      //  Authorization: getToken(),
    },
  };

  try {
    const response = await axios(options);
    return response.data.datos; 
  } catch (error) {
    console.error("Error en la solicitud:", error);
    throw error; 
  }
};





  
  
