import { useEffect, useState } from "react"
import {
  obtenerTiposDocumento,
  obtenerTiposPersona,
} from "../utils/apiRegistroUsuario"

export const useFetchTiposDocumentos = () => {
  const [listaTipoDocumento, setListaTipoDocumento] = useState([])

  useEffect(() => {
    const getTypesDocument = async () => {
      try {
        const tipoDocumento = await obtenerTiposDocumento()
        setListaTipoDocumento(tipoDocumento)
      } catch (error) {
        console.log("Error al obtener los tipos de documento:", error)
      }
    }

    getTypesDocument()
  }, [])

  return listaTipoDocumento
}

export const useFetchTipoPersona = () => {
  const [listaTipoPersona, setListaTipoPersona] = useState([])

  useEffect(() => {
    const getTypesPerson = async () => {
      try {
        const tipoPersona = await obtenerTiposPersona()
        setListaTipoPersona(tipoPersona)
      } catch (error) {
        console.log("Error al obtener los tipos de personao:", error)
      }
    }

    getTypesPerson()
  }, [])

  return listaTipoPersona
}
