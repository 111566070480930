import axios from "axios"

const baseURL = "https://devshopapi.encarga.app"

export const obtenerProductosGeneral = async () => {
  const options = {
    method: "GET",
    url: `${baseURL}/productos/all/users`,
    headers: {
      // Authorization: getToken(),
    },
  }

  try {
    const response = await axios.request(options)
    return response.data.productos
  } catch (error) {
    console.error("Error al obtener los productos:", error)
    throw error
  }
}

export const obtenerCategorias = async () => {
  const options = {
    method: "GET",
    url: `${baseURL}/categorias`,
    headers: {
      //  Authorization: getToken(),
    },
  }

  try {
    const response = await axios(options)
    return response.data
  } catch (error) {
    console.error("Error en la solicitud:", error)
    throw error
  }
}

export const obtenerProductosPorCategoria = async (codCategoria) => {
  const options = {
    method: "GET",
    url: `${baseURL}/productos/categoria/${codCategoria}`,
    headers: {
      // Authorization: getToken(),
    },
  }

  try {
    const response = await axios(options)
    return response.data
  } catch (error) {
    console.error("Error en la solicitud:", error)
    throw error
  }
}

export const obtenerProductosPorSubcategoria = async (codCategoria, codSubcategoria) => {
  const options = {
    method: "GET",
    url: `${baseURL}/productos/categoria/${codCategoria}/${codSubcategoria}`,
    headers: {
      // Authorization: getToken(),
    },
  }

  try {
    const response = await axios(options)
    return response.data
  } catch (error) {
    console.error("Error en la solicitud:", error)
    throw error
  }
}


export const obtenerProductosPorVendedor = async (codVendedor) => {
  const options = {
    method: "GET",
    url: `${baseURL}/productos/${codVendedor}`,
    headers: {
      // Authorization: getToken(),
    },
  }

  try {
    const response = await axios(options)
    return response.data
  } catch (error) {
    console.error("Error en la solicitud:", error)
    throw error
  }
}

